import store from '../store';
const axios = require('axios');

function isAdmin(){
    return store.getters.isAdmin
}
function userType(){
    const userType = store.getters.isAdmin ? 'admin' : 'client'
    return userType
} 
//const baseURL = process.env.NODE_ENV === 'development' ? 'http://lujanapidev.slang.digital/api/' : 'http://lujanapi.slang.digital/api/'
const baseURL ='https://lujanapi.slang.digital/api/'
//http://localhost:8081/api/
//const baseURL = "http://localhost:8081/api/"


// =============== USER / AUTH  METHODS ===============
export async function UserLogIn(email, password){ // Login

    const headers = {}
    const data = { email, password };
    
    const response = await axios.post(baseURL + "auth/login?populate=client", data, { headers })
    console.log(response.data)
    store.commit('SetCurrentUser', response.data)
    

    return response.data
}

export async function TokenAuth( token ){ // Login with token

    const headers = { 'utoken' : token }
    
    const response = await axios.post(baseURL + "auth/logintoken?populate=client", {}, { headers })
    store.commit('SetCurrentUser', response.data)
    
    return response.data
}

export async function UserLogOut(){ // Logout

    if ( !sessionStorage.getItem('utoken') ) { return }

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.post(baseURL + "auth/logout" , {} ,{ headers })
    store.commit('SetCurrentUser', undefined)
    
    return response
}


function BuildQueries(filters, sortValues, start, limit){ // Construcción de queries

    let queries = []

    if ( sortValues && Object.keys(sortValues).length > 0 ) { // Ordenación por campos

        queries.push( Object.entries(sortValues).map((sort) => { return 'sortby=' + sort[0] + '&sortdir=' + sort[1] } ).join('&') )
    }

    if ( filters && Object.keys(filters).length > 0 ) { // Filtros
        
        queries.push( Object.entries(filters).filter(filter => filter[1]).map((item) => { return item[0] + '=' + item[1] } ).join('&') )

    }

    if ( start ) queries.push('start=' + start)
    if ( limit ) queries.push('limit=' + limit) // Limite de entradas

    return queries.join('&')
}


export async function GetOrders( accepted, filters, sortValues, start, limit ){ // Obtener pedidos

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    
    // ----- Queries -----
    let queries = BuildQueries(filters, sortValues, start, limit)
    
    accepted ? queries += '&accepted=true' : queries += '&accepted=false' // Pedido o presupuesto
    
    if(isAdmin()){
        const response = await axios.get(baseURL + "admin/orders?populate=client" + '&' + queries, { headers })
        return response.data
    }else{
        const response = await axios.get(baseURL + "client/orders?populate=client"+`&client=${store.getters.currentUser.user.client._id}` + '&' + queries, { headers })
        return response.data
    }
        

    
}

export async function GetClientsAdmin(filters, sortValues, start, limit){ // Obtener clientes
    
    const headers = { 'utoken': sessionStorage.getItem('utoken') }

    // ----- Queries -----
    let queries = BuildQueries(filters, sortValues, start, limit)

    const response = await axios.get(baseURL + "admin/clients" + '?' + queries, { headers })
    return response
}


export async function GetProducts(filters, sortValues, start, limit){ // Obtener productos
    
    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    // ----- Queries -----
    let queries = BuildQueries(filters, sortValues, start, limit)
    
    const response = await axios.get(baseURL + userType()+"/products" + '?populate=manufacturer&' + queries, { headers })

    
    return response

}

export async function GetProductByID(id){ // Obtener productos

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
  
    const response = await axios.get(baseURL + userType()+"/products/" + id, { headers })

    return response

}



export async function GetClientByIDAdmin(clientId){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }  
    const response = await axios.get(baseURL + "admin/clients/" + clientId, { headers })

    return response
}


export async function CreateClientAdmin(data){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.post(baseURL + "admin/clients", data, { headers })

    return response
}

export async function UpdateClientAdmin(clientID,data){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    
    const response = await axios.put(baseURL + "admin/clients/"+clientID, data, { headers })

    return response
}


// ORDERS


export async function CreateOrderAdmin(body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 
    
    const response = await axios.post(baseURL + userType()+"/orders?populate=user", body,{ headers })

    return response
}

export async function DeleteOrderAdmin(orderId){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    
    const response = await axios.delete(baseURL + userType()+"/orders/" + orderId,{ headers })
    return response

}

export async function UpdateOrderAdmin(id, body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 

    const response = await axios.put(baseURL + userType()+"/orders/" + id, body, { headers })
    
    return response
}

export async function UpdateOrderLineAdmin(id, body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 
    
    const response = await axios.put(baseURL + userType()+"/orderlines/"+ id + "?populate=product", body,{ headers })
    
    return response
}

export async function GetOrderLineAdmin(orderId){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 
    const response = await axios.get(baseURL + userType()+`/orderlines?order=${orderId}&populate=product`, { headers })

    return response

}


export async function CreateOrderLineAdmin(body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }

    const response = await axios.post(baseURL + userType()+'/orderlines?populate=product', body, { headers })
    return response

}



//Productos

export async function CreateNewProduct(body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }   
    const response = await axios.post(baseURL + "admin/products", body,{ headers })
    return response

}

export async function ImportProducts(file,location){

    const headers = { 'utoken': sessionStorage.getItem('utoken'),"location": location }   

    let formData = new FormData();
    
        formData.append(0,file)
    
    const response = await axios.post(baseURL + "admin/products/importFromXlsx", formData,{ headers })
    return response

}

export async function UpdateProduct(id,body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }   
    
    const response = await axios.put(baseURL + "admin/products/" + id, body,{ headers })
    return response

}

export async function DeleteProduct(id){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }   
    
    const response = await axios.delete(baseURL + "admin/products/" + id, { headers })
    return response

}

export async function DeleteClient(id){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }   
    
    const response = await axios.delete(baseURL + `admin/clients/${id}?cascade=true`, { headers })
    return response


}

export async function CreateUser(body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }   
    
    const response = await axios.post(baseURL + "admin/users/" , body, { headers })
    return response

}

export async function GetUsersByID(client){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 
    const response = await axios.get(baseURL + `admin/users?client=${client}&sortby=createAt&sortdir=desc`, { headers })

    return response



}

export async function UpdateUserAdmin(userId,body){
    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.put(baseURL + `admin/users/${userId}`,body, { headers })
    
    return response
}

export async function DeleteUserAdmin(userId){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }   
    const response = await axios.delete(baseURL + `admin/users/${userId}`, { headers })
    return response

}

export async function DeleteClientsAdmin(clientsID){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.delete(baseURL + `admin/clients/?ids=${clientsID.toString()}&cascade=true`, { headers })
    return response

}

export async function DeleteProductsAdmin(productsID){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.delete(baseURL + `admin/products/?ids=${productsID.toString()}&cascade=true`, { headers })
    return response

}

export async function DeleteOrdersAdmin(ordersID){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.delete(baseURL + userType()+`/orders/?ids=${ordersID.toString()}`, { headers })
    return response

}

export async function DeleteOrderLinesAdmin(ordersLineID){

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    
    const response = await axios.delete(baseURL + userType()+`/orderlines/?ids=${ordersLineID.toString()}`, { headers })
    return response

}

//METODOS DEL CLIENTE

export async function GetProductsClient(filters, sortValues, start, limit){ // Obtener productos

    const headers = { 'utoken': sessionStorage.getItem('utoken') }

    // ----- Queries -----
    let queries = BuildQueries(filters, sortValues, start, limit)
    
    const response = await axios.get(baseURL + "client/products" + '?' + queries, { headers })

    return response

} 

export async function UploadFileToOrder(files,id){
    

    const headers = { 'utoken': sessionStorage.getItem('utoken'),'Content-Type': 'multipart/form-data' }

    let formData = new FormData();
    files.forEach((file,index) => {
        formData.append(index,file)
    })

    formData.forEach((data) =>{
        data.arrayBuffer().then((response)=>{
            console.log(response)
        })
    })
    
    const response = await axios.post(baseURL + userType()+'/storage/'+id, formData, { headers }) 
    return response
}

export async function DeleteFiles(filesNames,id){
    

    const headers = { 'utoken': sessionStorage.getItem('utoken') }
    const response = await axios.put(baseURL + userType()+'/storage/'+id, {filesNames}, { headers })

    return response
}

export async function GetAttachFiles(fileName,id){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 
    
    //const response = await axios.get(baseURL + userType()+'/storage/'+id+'?filename='+fileName,  { headers, responseType })


    return await axios({
        method: 'get',
        url: baseURL + userType()+'/storage/'+id+'?filename='+fileName,
        headers,
        responseType: "arraybuffer"
    })
}

export async function GetManufacturer(){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 

    const response = await axios.get(baseURL + userType()+'/manufacturer/', { headers })

    return response

}

export async function GetClientsPhones(phone){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 

    const response = await axios.get(baseURL + userType()+`/phones/?phone=${phone}`, { headers })

    return response

}

export async function SendMessageToClient(body){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 

    const response = await axios.post(baseURL + userType()+`/phones/`,body, { headers })

    return response

}

export async function GetAnalytics(){

    const headers = { 'utoken': sessionStorage.getItem('utoken') } 

    const response = await axios.get(baseURL + userType()+`/analytics`, { headers })

    return response

}

export async function SearchProduct(searchData,location){

    const headers = { 'utoken': sessionStorage.getItem('utoken'),'location':location }
    const response = await axios.get(baseURL + userType()+`/search/products/${searchData}`, { headers })

    return response

}