<template>
    <div class="createProductContainer">

        <div style="padding: 24px">

            <!-- <div class="dataInput">
                <div class="dataFieldName" style="width:104px">Capitulo</div>
                <p v-if="this.isView">{{this.product.cap}}</p>
                <input v-else class="dataFieldInput" type="text" placeholder="Descripción de producto" v-model="this.product.cap" ref="productDescriptionInput" @focus="$event.currentTarget.style='1px solid var(--main_75)',ControlInfoText()"/>
            </div> -->
            <div class="dataInput">
                <div class="dataFieldName" style="width:104px">Descripción</div>
                <p v-if="this.isView">{{this.product.description}}</p>
                <input v-else class="dataFieldInput" type="text" placeholder="Descripción de producto" v-model="this.product.description" ref="productRefInput" @focus="$event.currentTarget.style='1px solid var(--main_75)',ControlInfoText()"/>
            </div>
            <div class="dataInput">
                <div class="dataFieldName" style="width:104px">Neto</div>
                <p v-if="this.isView">{{this.product.price}}</p>
                <input v-else class="dataFieldInput" type="number" placeholder="Precio de producto" v-model="this.product.price" ref="productPriceInput" @focus="$event.currentTarget.style='1px solid var(--main_75)',ControlInfoText()"/>
            </div>
            <div class="dataInput">
                <div class="dataFieldName" style="width:104px">Tarifa</div>
                <p v-if="this.isView">{{this.product.location}}</p>
                <select v-else class="dataFieldInput" v-model="this.product.location" style="height:35px" ref="productManufacturer" @focus="$event.currentTarget.style.border='1px solid var(--main_75)',ControlInfoText()">
                    <option >Seleccionar</option>
                    <option v-for="location in this.locations" :key="location">{{location}}</option>
                    
                </select>
            </div>
            <p v-if="!this.isView" style="font-size:12px;margin-top:8px" ref="infoText">Todos los campos son obligatorios</p>

            <div class="qrImage" v-if="this.isView">
                <img src="..\assets\icons\qr-code.png">
            </div>
            
        </div>
        
        <div class="footerContainer">
            
            <!-- <button v-if="this.isView" class="primaryButton">Salir</button> -->
            <button class="primaryButton" @click="this.UpdateAProduct()" v-if="this.product.id && !this.isView"><img src="..\assets\icons\saveIco.svg"><p>Guardar y salir</p></button>
            <button class="primaryButton" @click="this.CreateNewProduct()" v-if="!this.product.id"><img src="..\assets\icons\saveIco.svg" ><p>Añadir Producto</p></button>
            
        </div>
    </div>
</template>

<script>

import {CreateNewProduct,UpdateProduct} from '../helpers/APIconnection'
import store from '../store'

export default {

    data:function(){
        return{
            product:{},
            isView:false,

            locations:{
                madrid:"Madrid",
                murcia:"Murcia"
            }
            
        }
    },

    mounted(){

        this.isView = store.getters.appGlobalVariables.generalModalParameters.view
        
        if(!this.isView){
            this.isView=false
        }
        
        
        if(store.getters.appGlobalVariables.generalModalParameters.product){
            this.product = Object.create(store.getters.appGlobalVariables.generalModalParameters.product)   
            
        }else{
            
            this.product.location='Seleccionar'
            
        }
        // GetManufacturer().then((response) => {
          
        //     this.manufacturs=response.data.items
           
        // }).catch((error) => {
        //     console.log(error.response)
        // })
        
    },

    methods:{

        ControlInfoText:function(){
            
            if(!this.$refs.productRefInput.style.border && !this.$refs.productDescriptionInput.style.border && !this.$refs.productPriceInput.style.border){
                this.$refs.infoText.style.color=""
                
            }
           
        },

        CheckFields:function(){
            var fieldEmpty = false

            if(!this.product.description){
                this.$refs.productRefInput.style="border:1px red solid"
                fieldEmpty=true
                this.$refs.infoText.style.color="red"
                
            }
            if(!this.product.price){
                this.$refs.productPriceInput.style="border:1px red solid"
                fieldEmpty=true
                this.$refs.infoText.style.color="red"
                
            }
            if(this.product.location=='Seleccionar'){
                this.$refs.productManufacturer.style="border:1px red solid;height:35px"
                fieldEmpty=true
                this.$refs.infoText.style.color="red"
            }
            return fieldEmpty

        },
        
        CreateNewProduct:function(){

            if(!this.CheckFields()){

                CreateNewProduct(this.product).then(() => {   
                    store.getters.appGlobalVariables.generalModalParameters.callback()
                    store.commit('CreateToastMessage', { id: 'CreateProduct', type: 'error', title: 'Producto creado con éxito', text: '', timeAlive: 5000 })
                    store.commit("ShowGeneralModalParameters",undefined)
                }).catch((error)=>{
                    store.commit('CreateToastMessage', { id: 'loginError', type: 'error', title: 'Error', text: error.response.data.error[0].msg, timeAlive: 5000 })      
            })

            }
            

        },

        UpdateAProduct:function(){


            if(!this.CheckFields()){

                UpdateProduct(this.product.id,
                    {'description':this.product.description,'price':this.product.price,'location':this.product.location}
                ).then(()=>{store.getters.appGlobalVariables.generalModalParameters.callback()
                    store.commit('CreateToastMessage', { id: 'updateProduct', type: 'error', title: 'Producto actualizado con éxito', text: '', timeAlive: 5000 })
                    store.commit("ShowGeneralModalParameters",undefined)})
                .catch((error)=>{ 
                    store.commit('CreateToastMessage', { id: 'updateProduct', type: 'error', title: 'Error', text: error.response.data.error[0].msg, timeAlive: 5000 })
                    console.log(error.response)})

            }

        },

    }

}
</script>

<style>

.createProductContainer{
    width: 600px;
    display: flex;
    flex-flow: column;
}

.qrImage{
    display: flex;
    justify-content: center;
    margin-top: 12px;
    padding-top: 12px;
    border-top:1px solid var(--main_100);
}
.qrImage img{
    width: 200px;
    height: 200px;
}
</style>