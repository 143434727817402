<template>
    <div class="globalOverlay">

        <div class="modalContainer" :style="this.GetVariables.style">
            
            <div class="modalHeader">

                <div class="modalTitle">
                    {{this.GetVariables.title}}
                </div>

                <div class="modalButtonsContainer">
                    <img src="..\assets\icons\window_quit.svg" alt="X" @click="this.closeButtonFunction">
                </div>
            </div>

            <component :is="this.GetVariables.component" v-on:OverrideCloseButton="this.closeButtonFunction = $event" />
        </div>
    </div>
</template>

<script>

import store from '../store'

import CreateEstimate from './CreateEstimate.vue'
import CreateClient from './CreateClient.vue'
import CreateProduct from './CreateProduct.vue'
import CreateBudget from './CreateBudget.vue'

export default {
    data: function(){
        return {
            
            variables: store.getters.appGlobalVariables.generalModalParameters,
            closeButtonFunction: this.CloseModal
            
        }
    },

    components: { CreateClient, CreateEstimate, CreateProduct, CreateBudget},

    methods:{
        CloseModal: function(){
            store.commit("ShowGeneralModalParameters", undefined)
        }
    },

    computed:{

        GetVariables(){
            return store.getters.appGlobalVariables.generalModalParameters;
        }

    }
    

}
</script>

<style>

.globalOverlay {

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    inset: 0;
    background: rgb(14, 59, 67, 0.3); 
    backdrop-filter: blur(15px);
   
}

.modalContainer {

    display: flex;
    flex-flow: column;
    min-height: 250px;
    border-radius: 16px;  
    background: rgb(255, 255, 255);
    border: var(--main_100) 1px solid;
    overflow: hidden;

    animation: swipeUp 0.5s;
}

.modalHeader {

    display: flex;
    justify-content: space-between;
    height: 56px;
    align-items: center;
    padding: 24px;
    background: var(--main_100);
    user-select: none;
}

.modalHeader .modalTitle {
    
    font-weight: normal;
    font-size: 20px;
    color: #fff;
}

.modalHeader .modalButtonsContainer {
    display: flex;
    gap: 4px;
}

.modalButtonsContainer img {
    height: 18px;
    cursor: pointer;
}



.primaryButton, .secondaryButton {

    display: flex;
    align-items: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 12px 16px;
    border: none;       
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: background 0.2s;
}

.primaryButton {
    border: 1px solid var(--cta_100);
    background: var(--cta_100);
    color: #FFF;
}

.secondaryButton {
    border: 1px solid var(--cta_100);
    color: var(--cta_100);
    background: #FFF;
}

.primaryButton:hover {
    background: var(--cta_100);
}

.primaryButton:active {
    background: var(--cta_100);
}
/* ========== FOOTER STYLES ========== */

.footerContainer{
    height: 64px;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    background: var(--main_10);
}

.footerContainer button p{
    
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    margin-left: 4px;
}


/* ========== SECTION MENU STYLES ========== */

.sectionMenu {
	height: 32px;
	position: relative;
	display: flex;
	align-items: center;
	gap: 32px;
}

.sectionMenu .sectionMenuMark {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background: var(--main_100);

    transition: left 0.3s, width 0.3s;
}

.sectionMenu .sectionOption {
	position: relative;
	height: 32px;
	display: flex;
	align-items: center;
	padding: 0 8px;
    font-size: 14px;
	cursor: pointer;
	user-select: none;
}

.sectionMenu .sectionMenuSeparator {
	position: absolute;
	height: 1px;
	left: -24px;
	bottom: 0;
	right: -24px;
	background: var(--main_50);
	box-shadow: -2px -1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15);
}

/* ========== SCROLL CONTENT STYLES ========== */

.modalContainer .contentScrollWrap {
    position: relative;
    height: 100%;
    width: 100%;
}

.contentScrollWrap .contentScroll {
    position: absolute;
    inset: 0;
    overflow-y: auto;
}

.contentScroll::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.contentScroll::-webkit-scrollbar-track {
    background: var(--main_10);
}

/* Handle */
.contentScroll::-webkit-scrollbar-thumb {
    background: var(--main_100);
    border-radius: 4px;
}
</style>