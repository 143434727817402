<template>
  <div class="createEstimate">
      <div class="sectionMenu" >
            <!-- <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 4)" ref="menuOption3" style="font-size:18px">General</div> -->
			<div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 0)" ref="menuOption0" style="font-size:18px">Datos de Cliente</div>
			<div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 1)" ref="menuOption1" style="font-size:18px">Datos de Elaboración</div>
            <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 2)" ref="menuOption2" style="font-size:18px">Encastres y accesorios</div>
            <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 3)" ref="menuOption3" style="font-size:18px">Añadir Planos</div>
            <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 4)" ref="menuOption4" style="font-size:18px">Presupuesto</div>
			<div class="sectionMenuMark" ref="sectionMenuMark"/>

			<div class="sectionMenuSeparator"></div>
		</div>

        <div class="createEstimateContent">
            <div class="contentScrollWrap">
                <div class="contentScroll">
                
                <div v-show="this.currentSection==0 " class="menuSection">
                    
                    <div style="display:flex;justify-content:start">
                        <p style="font-weight:bold;font-size:24px">DATOS DEL CLIENTE Y DE LA TIENDA</p>
                    </div>

                    <div class="columnContainer">
                        <div class="column" style="width: 70%"> 
                            <div class="dataInput" >
                                <div class="dataFieldName">Nombre Cliente:*</div>                                      
                                <input class="dataFieldInput" type="text" placeholder="Nombre y apellidos" v-model="orderData.clientOrder.name" :disabled="this.isView"/>                
                            </div>
                            
                        </div>

                        <div class="column" style="width: 40%"> 
                            <div class="dataInput">
                                <div class="dataFieldName"><p>Referencia:</p></div>       
                                <input class="dataFieldInput" type="text" placeholder="Referencia" v-model="orderData.clientOrder.ref" :disabled="this.isView"/>
                            </div>
                        </div>
                    </div>

                    <div class="dataInput" >
                        <div class="dataFieldName">Dirección Completa:*</div>
                        <input class="dataFieldInput" type="text" placeholder="Dirección" v-model="orderData.clientOrder.address" :disabled="this.isView"/>
                    </div>
                
                    <div class="dataInput" >
                        <div class="dataFieldName">Teléfonos contacto:*</div>
                            <input class="dataFieldInput" type="text" v-model="orderData.clientOrder.phones" :disabled="this.isView"/>
                    </div>

                        <div class="columnContainer" >

                            <div class="column" > 
                                <div class="dataInput" >
                                    <div class="dataFieldName">Tienda:*</div>
                                    <input class="dataFieldInput" type="text" placeholder="Nombre tienda" v-model="orderData.clientOrder.storeName" :disabled="this.isView"/>
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName" >Montador:</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="Nombre Montador" v-model="orderData.clientOrder.fitterName"/>
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName" >Fecha de Medición:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="date" placeholder="01/03/2022"  v-model="orderData.clientOrder.measurementDate"/>
                                </div>
                                
                            </div>

                            <div class="column" > 
                                <div class="dataInput">
                                    <div class="dataFieldName" ><p>Vendedor:</p></div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="Vendedor" v-model="orderData.clientOrder.sellerName"/>
                                </div>
                                <div class="dataInput">
                                    <div class="dataFieldName"><p>Tlf. Montador:</p></div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text"  v-model="orderData.clientOrder.fitterPhone"/>
                                </div>
                                <div class="dataInput">
                                    <div class="dataFieldName" style="width:70%"><p>Marcar si es definitiva:</p></div>
                                        <div class="dataFieldInput" style="display:flex;justify-content:flex-start;aling-content:center;border:none;padding:4px">
                                            <input  type="checkbox"  style="height:20px;width:20px;" v-model="orderData.clientOrder.definitive" :disabled="this.isView"/>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="dataInput" style="display:flex;justify-content:center;align-items:center" v-if="this.chatId">
                            <input class="dataFieldInput" type="text" v-model="this.message"/>
                            <button class="secondaryButton" style="padding:0px 28px" @click="this.SendMessage()"> Enviar mensaje</button>
                        </div>
                        <div class="dataInput" style="display:flex;justify-content:right">
                            <button class="secondaryButton" style="padding:0px 28px;height:32px" @click="this.OnMenuElementClick(this.$refs.menuOption1, 1)"> Siguiente</button>
                        </div>
                </div>


                <div v-show="this.currentSection==1" class="menuSection">
                    
                    <div style="display:flex;justify-content:start">
                        <p style="font-weight:bold;font-size:24px">DATOS DE ELABORACIÓN</p>
                    </div>
                     
                    <p style="font-size:16px;font-weight:bold;margin-top:12px">Material Encimera Acabado y Grosor</p>
                        
                    <div class="columnContainer" style="margin-top:10px">

                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Material 1*</div>
                                    <!-- <input class="dataFieldInput" type="text" placeholder="" v-model="orderData.elaborationData.material1"/> -->

                                        <div v-if="this.isView"> <b v-if="this.selectedProduct.product1"><p>{{this.selectedProduct.product1.description}}</p></b> </div>
                                        <div class="inputWrap" v-else>
                                            <div class="inputImgContainer">
                                                <img src="@/assets/icons/searchIcon_main.svg" alt="">
                                            </div>
                                                    
                                            <div style="height: 100%; width: 100%; position: relative">
                                                <input type="text" placeholder="Buscar" style="width: 640px" v-model="this.productSearch.product1" @input="this.ProductSearchFunction('product1')" @blur="this.PreventBlurEvent($event,'product1')">

                                                <div class="selectedEntry" v-if="this.selectedProduct.product1" :title="this.selectedProduct.product1.description">
                                                    <p>{{this.selectedProduct.product1.description}}</p>
                                                    <img src="@/assets/icons/cross_white.svg" alt=""  @click="this.productSearch.product1 = ''; this.selectedProduct.product1 = undefined">
                                                </div>

                                                <div class="entriesSearch" tabindex="1" v-if="this.autocompleteProducts.product1 && Object.keys(this.autocompleteProducts.product1).length > 0">
                                                    <div class="entryElement" v-for="product in this.autocompleteProducts.product1" :key="product" @click="this.selectedProduct.product1 = product; this.autocompleteProducts.product1 = undefined">
                                                        {{product.description}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tinyLoadingSpinner main center" v-if="this.loadingProducts.product1"></div>
                                        </div>
                                    
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Material 2</div>
                                    <!-- <input class="dataFieldInput" type="text" placeholder="" v-model="orderData.elaborationData.material2"/> -->
                                    <div v-if="this.isView"> <b v-if="this.selectedProduct.product2"><p>{{this.selectedProduct.product2.description}}</p></b> </div>
                                        <div class="inputWrap" v-else>
                                            <div class="inputImgContainer">
                                                <img src="@/assets/icons/searchIcon_main.svg" alt="">
                                            </div>

                                            <div style="height: 100%; width: 100%; position: relative">
                                                <input type="text" placeholder="Buscar" style="width: 640px" v-model="this.productSearch.product2" @input="this.ProductSearchFunction('product2')" @blur="this.PreventBlurEvent($event,'product2')">

                                                <div class="selectedEntry" v-if="this.selectedProduct.product2" :title="this.selectedProduct.product2.description">
                                                    <p>{{this.selectedProduct.product2.description}}</p>
                                                    <img src="@/assets/icons/cross_white.svg" alt=""  @click="this.productSearch.product2 = ''; this.selectedProduct.product2 = undefined">
                                                </div>

                                                <div class="entriesSearch" tabindex="1" v-if="this.autocompleteProducts.product2 && Object.keys(this.autocompleteProducts.product2).length > 0">
                                                    <div class="entryElement" v-for="product in this.autocompleteProducts.product2" :key="product" @click="this.selectedProduct.product2 = product; this.autocompleteProducts.product2 = undefined">
                                                        {{product.description}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tinyLoadingSpinner main center" v-if="this.loadingProducts.product2"></div>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="separator"></div>
                    <div style="margin-top:20px"> 
                    <div class="columnContainer" style="gap:5px">
                        <div class="dataInput" >
                                    <div class="dataFieldName" style="width:fit-content;min-width:0">Copetes:</div>
                                    <input v-model="orderData.elaborationData.copete" v-if="this.isView" disabled style="padding:0 4px;width:135px">    
                                    <select v-else class="dataFieldInput" style="padding:0 4px;width:135px" v-model="orderData.elaborationData.copete">
                                            
                                            <option value="">SELECCIONAR</option>
                                            <option value="RECTO">RECTO</option>
                                            <!-- <option value="ROMO">ROMO</option> -->
                                            <!-- <option value="BISELADO">BISELADO</option> -->
                                            <!-- <option value="INCLINADO">INCLINADO</option> -->
                                        </select>

                                    
                                </div>

                                <div class="dataInput" >
                                    <div class="dataFieldName" style="width:fit-content;min-width:0;margin-right:0px">Altura(cm)</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" style="width:80px" v-model="orderData.elaborationData.copeteHeight"/>
                                </div>
                                
                        <div class="dataInput" style="margin-left:10px">
                            <div class="dataFieldName" style="width:110px;min-width:fit-content;">Canto Encimera:</div>
                            <input v-if="this.isView" v-model="orderData.elaborationData.cantoEncimera" style="padding:0 10px" disabled/>
                            <select v-else class="dataFieldInput" style="padding:0 10px" v-model="orderData.elaborationData.cantoEncimera">
                                <option value="">SELECCIONAR</option>
                                <option value="RECTO">RECTO</option>
                                <!-- <option value="BISELADO (1 CM)">BISELADO (1 CM)</option> -->
                                <!-- <option value="ROMO">ROMO</option> -->
                                <!-- <option value="REDONDO">REDONDO</option> -->
                                <option value="INGLETADO">INGLETADO</option>
                                <option value="OTRO">OTRO</option>
                                <!-- <option value="PICO FLAUTA">PICO FLAUTA</option> -->    
                            </select>
                        </div>
                        
                        <div class="dataInput" style="margin-left:10px">
                            <div class="dataFieldName" style="width:100px;min-width:fit-content">Canto Inglete:</div>
                            <input v-if="this.isView" style="padding:0 10px;width:120px" v-model="orderData.elaborationData.cantoInglete" disabled/>
                            <select v-else class="dataFieldInput" style="padding:0 10px" v-model="orderData.elaborationData.cantoInglete">
                                <option value="">SELECCIONAR</option>
                                <option value="LUJAN">LUJAN</option>
                                <option value="NORMAL">NORMAL</option>
                                <!-- <option value="SIN VUELTA<">SIN VUELTA</option> -->
                                    
                            </select>
                        </div>

                        <div class="dataInput" >
                            <div class="dataFieldName" style="width:fit-content;min-width:0;margin-right:0px" v-if="orderData.elaborationData.cantoInglete=='LUJAN'">Altura</div>        
                            <input v-if="orderData.elaborationData.cantoInglete=='LUJAN'" class="dataFieldInput" type="text" style="width:80px" v-model="orderData.elaborationData.cantoIngleteHeight" :disabled="this.isView"/>
                        </div>       

                    </div>

                </div>

                <div style="margin-top:20px">
                    <div class="dataInput">
                        <div class="dataFieldName" style="width:fit-content;min-width:0">Vuelos encimera(mm):</div>
                            <div class="dataFieldName" style="width:fit-content;min-width:0;margin-right:0px">Frente*</div>
                                <input :disabled="this.isView" class="dataFieldInput" type="text" style="width:80px" v-model="orderData.elaborationData.vuelosEncimeraFrente"/>
                                <div class="dataFieldName" style="width:fit-content;min-width:0;margin-right:0px">Lateral*</div>

                                <input :disabled="this.isView" class="dataFieldInput" type="text" style="width:80px" v-model="orderData.elaborationData.vuelosEncimeraCostados"/>

                                <div class="dataFieldName" style="width:70px;min-width:fit-content;margin-left:10%">Zona volada:</div>
                                <input v-if="this.isView" v-model="orderData.elaborationData.zonaVolada" disabled/>
                                <select v-else class="dataFieldInput" style="padding:0 10px;width:270px" v-model="orderData.elaborationData.zonaVolada">
                                    <option value="">SELECCIONAR</option>
                                    <option value="SIN TRATAR">SIN TRATAR</option>
                                    <option value="APOMAZADO (PORCELANICO)">APOMAZADO (PORCELANICO)</option>
                                    <option value="LIMPIA (CUARZO-GRANITO)">LIMPIA (CUARZO-GRANITO)</option>
                                    <option value="PULIDA (CUARZO-GRANITO)">PULIDA (CUARZO-GRANITO)</option>
                                    <option value="MELAMINA BLANCA">MELAMINA BLANCA</option>
                                    <option value="MELAMINA NEGRA">MELAMINA NEGRA</option>  
                                </select>
                        </div>
                </div>

                <div style="margin-top:10px">
                    <div class="dataInput">
                        <div class="dataFieldName" style="width:fit-content;min-width:0">Costados o Patas a Suelo:</div>
                        <div class="dataFieldName" style="width:fit-content;min-width:0">Indicar como se unen con la encimera:</div>
                        <input v-if="this.isView" v-model="orderData.elaborationData.costadosPatasSuelo" disabled/>
                        <select v-else class="dataFieldInput" style="padding:0 10px;width:270px" v-model="orderData.elaborationData.costadosPatasSuelo">
                            <option value="">SELECCIONAR</option>
                            <option value="A INGLETE CON LA ENCIMERA">A INGLETE CON LA ENCIMERA</option>
                            <option value="A TESTA CON LA ENCIMERA">A TESTA CON LA ENCIMERA</option>
                        </select>
                    </div>
                </div>

                <div class="dataInput" >
                        <div class="dataFieldName" style="width:fit-content;min-width:0;margin-right:0px">Observaciones</div>
                        <textarea style="height:60px;outline:none;width:90%;resize:none;padding:8px" resize="none" v-model="orderData.elaborationData.observations" :disabled="this.isView"></textarea>
                    </div> 
                    

                <div class="separator"></div>

                    <p style="font-size:16px;font-weight:bold;margin-top:12px">Material Trasera Acabado y Grosor</p>
                    <div class="columnContainer" style="margin-top:10px">
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Material 1</div>
                                    <!-- <input class="dataFieldInput" type="text" placeholder="" v-model="orderData.elaborationData.material3"/> -->
                                    <div v-if="this.isView"> <b v-if="this.selectedProduct.product3"><p>{{this.selectedProduct.product3.description}}</p></b> </div>
                                        <div class="inputWrap" v-else>
                                            <div class="inputImgContainer">
                                                <img src="@/assets/icons/searchIcon_main.svg" alt="">
                                            </div>

                                            <div style="height: 100%; width: 100%; position: relative">
                                                <input type="text" placeholder="Buscar" style="width: 640px" v-model="this.productSearch.product3" @input="this.ProductSearchFunction('product3','m2')" @blur="this.PreventBlurEvent($event,'product3')">

                                                <div class="selectedEntry" v-if="this.selectedProduct.product3" :title="this.selectedProduct.product3.description">
                                                    <p>{{this.selectedProduct.product3.description}}</p>
                                                    <img src="@/assets/icons/cross_white.svg" alt=""  @click="this.productSearch.product3 = ''; this.selectedProduct.product3 = undefined">
                                                </div>

                                                <div class="entriesSearch" tabindex="1" v-if="this.autocompleteProducts.product3 && Object.keys(this.autocompleteProducts.product3).length > 0">
                                                    <div class="entryElement" v-for="product in this.autocompleteProducts.product3" :key="product" @click="this.selectedProduct.product3 = product; this.autocompleteProducts.product3 = undefined">
                                                        {{product.description}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tinyLoadingSpinner main center" v-if="this.loadingProducts.product3"></div>
                                        </div>

                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Material 2</div>
                                    
                                    <!-- <input class="dataFieldInput" type="text" placeholder="" v-model="orderData.elaborationData.material4"/> -->
                                <div v-if="this.isView"> <b v-if="this.selectedProduct.product4"><p>{{[this.selectedProduct.product4.finalProduct, this.selectedProduct.product4.ref].join(', ')}}</p></b> </div>
                                        <div class="inputWrap" v-else>
                                            <div class="inputImgContainer">
                                                <img src="@/assets/icons/searchIcon_main.svg" alt="">
                                            </div>

                                            <div style="height: 100%; width: 100%; position: relative">
                                                <input type="text" placeholder="Buscar" style="width: 640px" v-model="this.productSearch.product4" @input="this.ProductSearchFunction('product4','m2')" @blur="this.PreventBlurEvent($event,'product4')">

                                                <div class="selectedEntry" v-if="this.selectedProduct.product4" :title="this.selectedProduct.product4.description">
                                                    <p>{{this.selectedProduct.product4.description}}</p>
                                                    <img src="@/assets/icons/cross_white.svg" alt=""  @click="this.productSearch.product4 = ''; this.selectedProduct.product4 = undefined">
                                                </div>

                                                <div class="entriesSearch" tabindex="1" v-if="this.autocompleteProducts.product4 && Object.keys(this.autocompleteProducts.product4).length > 0">
                                                    <div class="entryElement" v-for="product in this.autocompleteProducts.product4" :key="product" @click="this.selectedProduct.product4 = product; this.autocompleteProducts.product4 = undefined">
                                                        {{product.description}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tinyLoadingSpinner main center" v-if="this.loadingProducts.product4"></div>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dataInput" style="display:flex;justify-content:right">
                            <button class="secondaryButton" style="padding:0px 28px;height:32px" @click="this.OnMenuElementClick(this.$refs.menuOption2, 2)"> Siguiente</button>
                        </div>


                </div>

                <div v-show="this.currentSection==2" class="menuSection">
                    <div style="display:flex;justify-content:start">
                        <p style="font-weight:bold;font-size:24px">ENCASTRES Y ACCESORIOS</p>
                    </div>

                    <div class="columnContainer" style="margin-top:10px">
                        <div class="column" style="width:10%;display:flex;justify-content:center;margin-top:14px" > 
                            <p style="font-size:16px;font-weight:bold;width:86px">Placa</p>
                        </div>
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Marca:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.plateBrand"/>
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Ubicación placa:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.plateUbication"/>
                                </div>
                            </div>
                        </div>
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Modelo:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.plateModel"/>
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Referencia:</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.plateRef"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="separator"></div>

                    <div class="columnContainer" style="margin-top:10px">
                        <div class="column" style="width:10%;display:flex;justify-content:center;margin-top:14px" > 
                            <p style="font-size:16px;font-weight:bold">Fregadero</p>
                        </div>
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Marca:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.sinkBrand"/>
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Ubi. Fregadero:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.sinkUbication"/>
                                </div>
                                
                            </div>
                        </div>
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Modelo:*</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.sinkModel"/>
                                </div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Referencia:</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.sinkRef"/>
                                </div>
                                
                            </div>
                        </div>
                        
                        
                    </div>
                    
                    <div class="dataInput" style="margin-left:110px">
                                    <div class="dataFieldName" >Tipo mecanizado Fregadero respecto a la encimera:</div>
                                    <input v-if="this.isView" v-model="orderData.insertsAndAccesories.sinkMachinedType" disabled/>
                                    <select v-else style="width:200px" v-model="orderData.insertsAndAccesories.sinkMachinedType">
                                        <option>BAJO ENCIMERA</option>
                                        <option>SOBRE ENCIMERA</option>
                                    </select>
                                    
                                </div>

                    <div class="dataInput" style="margin-left:110px">
                        <div class="dataFieldName" style="min-width:fit-content">Mano escurridor:</div>
                                    <input v-if="this.isView" style="width:120px" v-model="orderData.insertsAndAccesories.handDrainer" disabled/>
                                    <select v-else style="width:120px" v-model="orderData.insertsAndAccesories.handDrainer">
                                        <option>NO LLEVA</option>
                                        <option>DERECHA</option>
                                        <option>IZQUIERDA</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content"> Suministrado por Lujan:</div>
                                    <input v-if="this.isView" v-model="orderData.insertsAndAccesories.sinkLujan" disabled style="width:135px"/>
                                    <select v-else style="width:135px" v-model="orderData.insertsAndAccesories.sinkLujan">
                                        <option value=''>SELECCIONAR</option>
                                        <option>SI</option>
                                        <option>NO</option>
                                    </select>


                    </div>
                    
                    <div class="separator"></div>

                    <div class="columnContainer" style="margin-top:10px">
                        <div class="column" style="width:10%;display:flex;justify-content:center; ;margin-top:14px" > 
                            <p style="font-size:16px;font-weight:bold;width:86px">Grifo</p>
                        </div>
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Marca:</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.faucetBrand"/>
                                </div>
                                
                                
                            </div>
                        </div>
                        <div class="column" style="width:90%"> 
                            <div>
                                <div class="dataInput" >
                                    <div class="dataFieldName">Modelo:</div>
                                    <input :disabled="this.isView" class="dataFieldInput" type="text" placeholder="" v-model="orderData.insertsAndAccesories.faucetModel"/>
                                </div>
                                
                                
                            </div>
                        </div>
                        
                        
                    </div>
                    
                    <div class="dataInput" style="margin-left:110px">
                                    <div class="dataFieldName" style="min-width:fit-content">Taladro:</div>
                                    <input v-if="this.isView" style="width:130px" v-model="orderData.insertsAndAccesories.faucetDrill" disabled/>
                                    <select v-else style="width:130px" v-model="orderData.insertsAndAccesories.faucetDrill">
                                        <option value="">SELECCIONAR</option>
                                        <option>SENCILLO</option>
                                        <option>DOBLE</option>
                                    </select>
                                    <div  class="dataFieldName" style="min-width:fit-content">Posición taladro grifo:</div>
                                    <input v-if="this.isView" style="width:145px" v-model="orderData.insertsAndAccesories.faucetDrillPosition" disabled />
                                    <select v-else style="width:145px" v-model="orderData.insertsAndAccesories.faucetDrillPosition">
                                        <option value="">SELECCIONAR</option>
                                        <option>CENTRADO</option>
                                        <option>A LA IZQUIERDA</option>
                                        <option>A LA DERECHA</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.faucetDrill=='DOBLE'">Posición taladro mando:</div>
                                    <input v-if="this.isView && orderData.insertsAndAccesories.faucetDrill=='DOBLE'" style="width:145px" v-model="orderData.insertsAndAccesories.faucetHandDrillPosition" disabled/>
                                    <select v-else-if="orderData.insertsAndAccesories.faucetDrill=='DOBLE'" style="width:145px" v-model="orderData.insertsAndAccesories.faucetHandDrillPosition">
                                        <option value="">SELECCIONAR</option>
                                        <option>A LA IZQUIERDA</option>
                                        <option>A LA DERECHA</option>
                                    </select>
                                </div>

                    <div class="separator"></div>
                    <div class="dataInput"></div>
                    <div class="dataInput" >
                                    <div class="dataFieldName" >Válvula Automática:</div>
                                    <input v-if="this.isView" style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.automaticValve" disabled/>
                                    <select v-else style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.automaticValve">
                                        <option>SI</option>
                                        <option>NO</option>
                                    </select>
                                    <div class="dataFieldName" v-if="orderData.insertsAndAccesories.automaticValve=='SI'">Colocación respecto a la posición del grifo:*</div>
                                    <input v-if="this.isView && orderData.insertsAndAccesories.automaticValve=='SI'" v-model="orderData.insertsAndAccesories.automaticValvePosition" disabled/>
                                    <select v-else-if="orderData.insertsAndAccesories.automaticValve=='SI'" style="width:150px" v-model="orderData.insertsAndAccesories.automaticValvePosition">
                                        <option value="">SELECCIONAR</option>
                                        <option>A LA IZQUIERDA</option>
                                        <option>A LA DERECHA</option>
                                    </select>
                    
                    </div>
                    <div class="dataInput"></div>
                    <div class="dataInput" >
                                    <div class="dataFieldName" >Dispensador:</div>
                                    <input v-if="this.isView" style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.dispenser" disabled/>
                                    <select v-else style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.dispenser">
                                        <option>SI</option>
                                        <option>NO</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content;" v-if="orderData.insertsAndAccesories.dispenser=='SI'">Modelo:*</div>
                                    
                                    <input v-if="orderData.insertsAndAccesories.dispenser=='SI'" class="dataFieldInput" style="width:280px;margin-right:20px" type="text" placeholder="" v-model="orderData.insertsAndAccesories.dispenserModel" :disabled="this.isView"/>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.dispenser=='SI'">Posición:*</div>
                                    <input v-if="this.isView && orderData.insertsAndAccesories.dispenser=='SI'" style="width:145px" v-model="orderData.insertsAndAccesories.dispenserPosition" disabled/>
                                    <select v-else-if="orderData.insertsAndAccesories.dispenser=='SI'" style="width:145px" v-model="orderData.insertsAndAccesories.dispenserPosition">
                                        <option value="">SELECCIONAR</option>
                                        <option>A LA IZQUIERDA</option>
                                        <option>A LA DERECHA</option>
                                    </select>
                                    
                    
                    </div>
                    <div class="dataInput" v-if="orderData.insertsAndAccesories.dispenser=='SI'">
                        <div class="dataFieldName" ></div> 
                        <div class="dataFieldName" style="min-width:fit-content" >Suministrado por Lujan:</div>
                        
                        <input v-if="this.isView && orderData.insertsAndAccesories.dispenser=='SI'" style="width:60px" v-model="orderData.insertsAndAccesories.dispenserLujan" disabled/>
                                   
                        <select v-else-if="orderData.insertsAndAccesories.dispenser=='SI'" style="width:135px" v-model="orderData.insertsAndAccesories.dispenserLujan">
                            <option value=''>SELECCIONAR</option>
                            <option>SI</option>
                            <option>NO</option>
                        </select>
                                    
                    </div>

                    <div class="dataInput"></div>
                    <div class="dataInput" >
                                    <div class="dataFieldName" style="">Tótem</div>
                                    <input v-if="this.isView" style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.totem" disabled/>
                                    <select v-else style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.totem">
                                        <option>SI</option>
                                        <option>NO</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.totem=='SI'">Modelo:*</div>
                                    <input v-if="orderData.insertsAndAccesories.totem=='SI'" class="dataFieldInput" type="text" placeholder="" style="width:280px;margin-right:20px"  v-model="orderData.insertsAndAccesories.totemModel" :disabled="this.isView"/>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.totem=='SI'">Diámetro Encastre:*</div>
                                    <input v-if="orderData.insertsAndAccesories.totem=='SI'" class="dataFieldInput" type="text" placeholder="" style="width:60px" v-model="orderData.insertsAndAccesories.insertDiameter" :disabled="this.isView"/>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.totem=='SI'">Número:*</div>
                                   
                                    <input v-if="orderData.insertsAndAccesories.totem=='SI'" class="dataFieldInput" type="text" placeholder="" style="width:60px" v-model="orderData.insertsAndAccesories.totemNumber" :disabled="this.isView"/>

                    
                    </div>
                    <div class="dataInput"></div>
                    <div class="dataInput" >
                                    <div class="dataFieldName" style="">Cubo:</div>
                                    <input v-if="this.isView" style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.cube" disabled/>
                                    <select v-else style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.cube">
                                        <option>SI</option>
                                        <option>NO</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.cube=='SI'">Modelo:*</div>
                                    
                                    <input v-if="orderData.insertsAndAccesories.cube=='SI'" class="dataFieldInput" style="width:280px;margin-right:20px" type="text" placeholder="" v-model="orderData.insertsAndAccesories.cubeModel" :disabled="this.isView"/>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.cube=='SI'">Tipo de mecanizado:*</div>
                                    <input v-if="this.isView" style="width:145px" v-model="orderData.insertsAndAccesories.cubeMachinedType" disabled/>
                                    <select v-else-if="orderData.insertsAndAccesories.cube=='SI'" style="width:145px" v-model="orderData.insertsAndAccesories.cubeMachinedType">
                                        <option value=''>SELECCIONAR</option>
                                        <option>S/E</option>
                                        <option>ENRASADO</option>
                                    </select>
                                    

                    
                    </div>
                    <div class="dataInput"></div>
                    <div class="dataInput">
                                    <div class="dataFieldName" style="" >Estrias:</div>
                                    <input v-if="this.isView" style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.slit" disabled/>
                                    <select v-else style="max-width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.slit">
                                        <option>SI</option>
                                        <option>NO</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.slit=='SI'">Mano:*</div>
                                    <input v-if="this.isView" style="width:145px;margin-right:20px" v-model="orderData.insertsAndAccesories.slitHand" disabled/>
                                    <select v-else-if="orderData.insertsAndAccesories.slit=='SI'" style="width:145px;margin-right:20px" v-model="orderData.insertsAndAccesories.slitHand">
                                        <option value="">SELECCIONAR</option>
                                        <option>A LA IZQUIERDA</option>
                                        <option>A LA DERECHA</option>
                                    </select>
                                    <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.slit=='SI'">Número:*</div>
       
                                    <input v-if="orderData.insertsAndAccesories.slit=='SI'" class="dataFieldInput" type="text" placeholder="" style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.slitNumber" :disabled="this.isView"/>
                                    
                                   

                    
                    </div>
                    <div class="dataInput"></div>
                    <div class="dataInput" >
                        <div class="dataFieldName" >Patas de acero:</div>
                        <input v-if="this.isView" style="width:60px" v-model="orderData.insertsAndAccesories.steelLegs" disabled />
                        <select v-else style="width:60px" v-model="orderData.insertsAndAccesories.steelLegs">
                            <option>SI</option>
                            <option>NO</option>
                        </select>
                        <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.steelLegs=='SI'">Pata Fija o Extensible LUJAN. Altura:*</div>
                        <input v-if="this.isView" style="width:110px" v-model="orderData.insertsAndAccesories.legsHeight" disabled/>
                        <select v-else-if="orderData.insertsAndAccesories.steelLegs=='SI'" style="width:110px" v-model="orderData.insertsAndAccesories.legsHeight">
                            <option value=''>SELECCIONAR</option>
                            <option>75CM</option>
                            <option>80CM</option>
                            <option>70 A 110CM</option>
                        </select>
                        
                        <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.steelLegs=='SI'">Pata trineo LUJAN. Acabado:*</div>
                        <input v-if="this.isView" style="width:150px" v-model="orderData.insertsAndAccesories.legFinish" disabled/>
                        <select v-else-if="orderData.insertsAndAccesories.steelLegs=='SI'" style="width:150px" v-model="orderData.insertsAndAccesories.legFinish">
                            <option value="">SELECCIONAR</option>
                            <option>ACERO MATE</option>
                            <option>ACERO BRILLO</option>
                            <option>LACADA BLANCO MATE</option>
                            <option>LACADA BLANCO BRILLO</option>
                            <option>LACADA NEGRO MATE</option>
                            <option>LACADA NEGRO BRILLO</option>
                        </select>
                        
                    </div>
                    <div class="dataInput">
                        <div class="dataFieldName" ></div> 
                        <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.steelLegs=='SI'">Alto(mm):*</div>
                        <input v-if="orderData.insertsAndAccesories.steelLegs=='SI'" class="dataFieldInput" type="text" placeholder="" style="width:100px" v-model="orderData.insertsAndAccesories.legFinishHeight" :disabled="this.isView"/>
                        <div class="dataFieldName" style="min-width:fit-content" v-if="orderData.insertsAndAccesories.steelLegs=='SI'">Ancho(mm):*</div> 
                        <input v-if="orderData.insertsAndAccesories.steelLegs=='SI'" class="dataFieldInput" type="text" placeholder="" style="width:100px" v-model="orderData.insertsAndAccesories.legFinishWidth" :disabled="this.isView"/>
                    </div>
                    <div class="dataInput"></div>

                    <div class="dataInput">

                        <div class="dataFieldName" style="">Conexión Placa:</div>
                        <input v-if="this.isView" style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.plateConnection" disabled/>
                        <select v-else style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.plateConnection">
                            <option>SI</option>
                            <option>NO</option>
                        </select>

                        <div class="dataFieldName" style="min-width:fit-content" >Conexión Totem:</div>
                        <input v-if="this.isView" style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.totemConnection" disabled/>
                        <select v-else style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.totemConnection">
                            <option>SI</option>
                            <option>NO</option>
                        </select>

                        <div class="dataFieldName" style="min-width:fit-content">Sellado Freg. S/E:</div>
                        <input v-if="this.isView" style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.sealedSink" disabled/>
                        <select v-else style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.sealedSink">
                            <option>SI</option>
                            <option>NO</option>
                        </select>

                        <div class="dataFieldName" style="min-width:fit-content">Fontanería:</div>
                        <input v-if="this.isView" style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.plumbing" disabled/>
                        <select v-else style="width:60px;margin-right:20px" v-model="orderData.insertsAndAccesories.plumbing">
                            <option>SI</option>
                            <option>NO</option>
                        </select>
                    </div>
                    
                    <div class="dataInput" v-if="orderData.insertsAndAccesories.plumbing=='SI'">
                        <div class="dataFieldName" style="width:fit-content;min-width:160px;margin-right:0px">Puntos a conectar:*</div>
                        
                        <textarea style="height:60px;outline:none;width:90%;resize:none;padding:8px" resize="none" v-model="orderData.insertsAndAccesories.plumbingPoints" :disabled="this.isView"></textarea>
                    </div>
                    <div class="dataInput"></div>
                    <div class="dataInput" >
                        <div class="dataFieldName" style="width:fit-content;min-width:0;margin-right:0px">Observaciones:</div>
                        <textarea style="height:60px;outline:none;width:90%;resize:none;padding:8px" resize="none" v-model="orderData.insertsAndAccesories.observations" :disabled="this.isView"></textarea>
                    </div>
                    <div class="dataInput" style="display:flex;justify-content:right">
                        <button class="secondaryButton" style="padding:0px 28px;height:32px" @click="this.OnMenuElementClick(this.$refs.menuOption3, 3)"> Siguiente</button>
                    </div>


                </div>
                <div v-show="this.currentSection==3" class="menuSection">

                    <div style="display:flex;justify-content:start;gap:12px">
                        <p style="font-weight:bold;font-size:24px">PLANOS ADJUNTOS</p>
                        <button v-if="this.currentSection==3 && !this.isView" @click="$event.currentTarget.firstChild.click()" class="addFilesButton"><input type="file" style="display:none" 
                            multiple="true" 
                            accept=".pdf,.jpg,.png,.mp4"
                            @change="this.SelectFile($event.currentTarget.files)">+</button>
                    </div>
                    <!-- <button @click="$event.currentTarget.firstChild.click()" class="secondaryButton"><input type="file" style="display:none" 
                    multiple="true" 
                    accept=".pdf,.jpg,.png"
                    @change="this.SelectFile($event.currentTarget.files)"
                    >Añadir adjuntos</button> -->

                    <!-- <div v-for="file,index in this.filesNames" :key="file" class="filesSelected" >
                       <p style="width:100%">{{file}}</p> 
                       <span v-if="this.orderData && this.orderData.attachedFiles && this.orderData.attachedFiles.includes(file)" style="cursor:pointer;margin:0px 10px" @click="this.GetAttachFile(file)">
                           <img src="..\assets\icons\download_main.svg" ></span>
                           <span style="cursor:pointer" @click="this.filesNames.splice(index,1); this.filesToDelete.push(file);$event.stopPropagation()">
                               <img src="..\assets\icons\cross_main.svg"></span>
                    </div> -->
                    <div class="filesArrayContainer">
                        
                        <div v-for="file,index in this.filesArray" :key="file" class="imageInArray" @click="$event.currentTarget.classList.toggle('selected')" tabindex="1" @blur="$event.currentTarget.classList.remove('selected')">
                            
                            <div class="selectOverlay"> </div>
                            <p class="fileNameText">{{file.name}}</p>
                            <img src= "..\assets\icons\cross_main.svg" class="cross" @click="DeleteAttachImage(file.name, index)" v-if="!this.isView">
                            <img src= "..\assets\icons\arrow_main.svg" class="cross" style="margin-left:150px" @click="downloadImage(file.url, file.name)">
                            <img style="height:200px;width:200px" src='..\assets\icons\pdfLogo.png' v-if="file.type=='application/pdf'">
                            <img style="height:200px;width:200px" src='..\assets\icons\pdfLogo.png' v-if="file.type=='video/mp4'">
                            <img v-else style="height:200px;width:200px" :src="file.url" >
                            
                        
                        </div>
                    </div>
                    
                    

                </div>
                <div v-show="this.currentSection==4 " class="menuSection">

                    <div class="inputFlexContainer">

						<div class="inputWrap">
							<div class="inputImgContainer">
								<img src="@/assets/icons/person_main.svg" alt="">
							</div>

							<div style="height: 100%; width: 100%; position: relative">
								<input type="text" placeholder="Ling Lee" style="width: 300px"  disabled>

								<div class="selectedEntry" v-if="this.selectedClient" :title="[this.selectedClient.clientRef, this.selectedClient.companyName, this.selectedClient.postalAddress, this.selectedClient.postalCode, this.selectedClient.city].join(', ')">
									<p>{{[this.selectedClient.clientRef, this.selectedClient.companyName].join(', ')}}</p>
									
								</div>
							</div>

						</div>

						<div class="inputWrap" >
							<div class="inputImgContainer">
								<img src="@/assets/icons/semaphore_main.svg" alt="">
							</div>

							<select style="width: 130px" v-if="this.orderData" v-model="this.orderData.accepted" >
								<option value="false">No aceptado</option>
								<option value="true">Aceptado</option>
							</select>

							
						</div>

						<div class="inputWrap" v-if="this.orderData && this.orderData.accepted && this.orderData.accepted.toString()=='true' && !this.CreateUserClientEstimate">
							<div class="inputImgContainer">
								<img src="@/assets/icons/semaphore_main.svg" alt="">
							</div>

							<select  v-if="this.orderData" v-model="this.orderData.status">
								<option value="0">En proceso</option>
								<option value="1">Pedido de material</option>
								<option value="2">Medición</option>
								<option value="3">Terminado</option>
							</select>
							
						</div>
						<!-- <div class="inputWrap" v-if="this.orderData && this.orderData.accepted && this.orderData.accepted.toString()=='true'">
							<button @click="OpenWorktopReserve()">Reserva de Encimera</button>

						</div> -->
					
						
					</div>
                    
                    <table id="my-table" class="entriesTable" style="margin-top: 16px" >
						<tr>
							<th> Producto </th>
							<th> Ud </th>
							<th> PRECIO </th>

							<th> LARGO(mm)</th>
							<th> ANCHO(mm)</th>
							<th> METROS </th>

							
							<th> Dto </th>
							<!-- <th> IVA </th> -->
							<th> IMPORTE </th>
							
							<!--th> <img src="@/assets/icons/semaphore_white.svg" class="iconCentered" alt=""> </th>
							<th> F.PREV </th-->
							
						</tr>

						<tr class="notHover" v-for="orderLine in this.orderLinesData" :key="orderLine">
							<td>{{ orderLine.product ? orderLine.product.description : '[ Producto no encontrado ]' }}</td>
							<td class="fitContent"> <input type="text" size="100" placeholder="0" v-model="orderLine.pieces" style="width: 42px" disabled> </td>
							<td class="rightAlign" style="width: 15%">{{ this.CurrencyNumberFormat(orderLine.product.price) }}</td>
							<!-- <td class="rightAlign" style="width: 15%">{{ orderLine.product !== null ? orderLine.product.price : '-' }}</td> -->

							<td class="fitContent"> <input type="text" placeholder="0.0" v-model="orderLine.height" v-if="orderLine.product.type!=='ud'" disabled><input type="text" placeholder="0.0" v-model="orderLine.height" v-if="orderLine.product.type=='ud'" disabled> </td>
							<td class="fitContent"> <input type="text" placeholder="0.0" v-model="orderLine.width" v-if="orderLine.product.type!=='ud'" disabled> <input type="text" placeholder="0.0" v-model="orderLine.height" v-if="orderLine.product.type=='ud'" disabled></td>
							<td class="fitContent"> <input type="text" placeholder="0.0" v-model="orderLine.meters" v-if="orderLine.product.type!=='ud'" disabled> <input type="text" placeholder="0.0" v-model="orderLine.height" v-if="orderLine.product.type=='ud'" disabled></td>

							
							<td class="fitContent"> <input type="text" placeholder="0%" v-model="orderLine.discount" v-if="this.CreateUserClientEstimate == undefined" disabled> <input v-else type="text" placeholder="0%" v-model="orderLine.discount" disabled></td>
							
							<!-- <td class="fitContent"> <input type="text" placeholder="0%" v-model="orderLine.vat"> </td> -->
							<td class="rightAlign" style="width: 15%">{{ this.GetOrderLineTotals(orderLine).pvp!=='Limite Excedido' ? this.CurrencyNumberFormat( this.GetOrderLineTotals(orderLine).pvp ) : 'Limite excedido' }}</td>
							<!--td class="fitContent"> <select style="width: 52px; font-size: 12px"> <option value="">EN</option></select> </td>
							<td class="fitContent"> <input type="date" placeholder="0%" style="width: 148px"> </td-->
							
							
						</tr>

						<tr class="notHover" v-if="!this.orderLinesData || Object.keys(this.orderLinesData).length === 0">
							<td colspan="9" class="center">Aún no se ha añadido ningún producto</td>
						</tr>

						<tr class="notHover" style="height: 56px">
							<td colspan='2' class="rightAlign" style="font-weight: 700">TOTAL</td>
							<td class="rightAlign">{{this.CurrencyNumberFormat(this.GetTotalPVT)}}</td>
							
							<td class="rightAlign" style="font-weight: 700" colspan=''>IVA</td>
							<td class="fitContent"> <input disabled type="text" placeholder="0%" v-if="this.orderData" v-model="this.orderData.totalVat"> </td>
							<td class="rightAlign" style="font-weight: 700" colspan=''>DTO</td>
							<td class="fitContent"> <input disabled type="text" placeholder="0%" v-if="this.orderData && this.CreateUserClientEstimate == undefined" v-model="this.orderData.totalDiscount" > <input type="text" placeholder="0%" v-if="this.orderData && this.CreateUserClientEstimate !== undefined" v-model="this.orderData.totalDiscount" disabled></td>
							<td class="rightAlign" style="font-weight: 700">{{this.CurrencyNumberFormat(this.GetTotalPVP)}}</td>
							
						</tr>

					</table>


                </div>
                
            </div>
        </div>
    </div>
        

  </div>
  <div class="footerContainer" style="justify-content: flex-end;display:flex;gap:10px">			
        <!-- <button class="secondaryButton" @click="OpenEditEstimateModal">Volver a presupuesto</button> -->
		
		<button v-if="!this.isView || this.CreateUserClientEstimate==undefined" class="primaryButton" style="position:static" @click="this.FinishBudget()">
			<div class="tinyLoadingSpinner wg" v-if="this.loader"></div>
			<p v-if="!this.loader">Guardar y salir</p>
		</button>
	</div>
</template>

<script>

//GetProducts,
import { UploadFileToOrder,CreateOrderAdmin,UpdateOrderAdmin,DeleteFiles,GetAttachFiles,GetClientsAdmin,GetProductByID,GetClientsPhones,SendMessageToClient,SearchProduct,GetOrderLineAdmin } from '../helpers/APIconnection'
// import CreateEstimate from '@/components/CreateEstimate'
import { CurrencyNumberFormat } from '../helpers/utils'
import store from '../store'
export default {
    emits:["OverrideCloseButton"],
    // components:{CreateEstimate},
    data:function() {
        return{
            
            chatId:undefined,
            message:'',
            orderData:{},
            orderLinesData:undefined,
            currentSection: 0,
            //selectedClient: undefined,
            isView:false,

            selectedProduct:{
                product1:undefined,
                product2:undefined,
                product3:undefined,
                product4:undefined

            },

            autocompleteClients: undefined,
            autocompleteProducts:{
                product1:undefined,
                product2:undefined,
                product3:undefined,
                product4:undefined

            },
            CreateUserClientEstimate:undefined,
            filesSelected:[],
            filesNames:[],
            filesToDelete:[],
            callback:undefined,
            duplicateOrder:false,
            loadingClients:false,
            isAdminUser:false,
            loadingProducts:{

                product1:false,
                product2:false,
                product3:false,
                product4:false

            },
            clientSearch:undefined,
            productSearch:{
                product1:undefined,
                product2:undefined,
                product3:undefined,
                product4:undefined

            },
            url:null,
            loader:false,
            filesArray:[]
        }
    },
    mounted(){
		this.OnMenuElementClick(this.$refs.menuOption0, 0)
        
        GetClientsPhones(this.orderData.clientOrder.phones).then((response)=>{    
            let phones = response.data
            if(phones.total>0){
                this.chatId=Object.values(phones.items)[0]?.chatID
            }
        })
        
	},

    created(){
        
        
        this.orderData = store.getters.appGlobalVariables.generalModalParameters.order
        this.isAdminUser=store.getters.isAdmin
       
        this.callback = store.getters.appGlobalVariables.generalModalParameters.callback
        this.CreateUserClientEstimate = store.getters.appGlobalVariables.generalModalParameters.userClient
        this.duplicateOrder = store.getters.appGlobalVariables.generalModalParameters.duplicateOrder
        this.isView = store.getters.appGlobalVariables.generalModalParameters.view

        if(!this.isView){
            this.isView=false
        }
  
        if(this.orderData && this.orderData.status>0 && this.CreateUserClientEstimate!==undefined){
            this.isView=true
        }


        this.$emit('OverrideCloseButton', async () => { 
				
			const callback = store.getters.appGlobalVariables.generalModalParameters.callback
				
			if ( callback ) { await callback() }	
			store.commit("ShowGeneralModalParameters", undefined)
			})

			if(this.CreateUserClientEstimate){
				this.selectedClient=store.getters.currentUser.user.client
			}
            
        if(!this.orderData){
            this.orderData={}
            
            
            Object.assign(this.orderData,{
                accepted:"true",
                feeType: this.isAdminUser ? 'Murcia' : store.getters.currentUser.user.client.feeType,
                clientOrder:{definitive:false,storeName: this.isAdminUser ? '' : store.getters.currentUser.user.client.companyName},
                elaborationData:{ 
                    copete:'',
                    cantoEncimera:'',
                    cantoInglete:'',
                    zonaVolada:''
                },
                insertsAndAccesories:{
                    sinkMachinedType:'BAJO ENCIMERA',
                    sinkLujan:'',
                    handDrainer:'NO LLEVA',
                    faucetDrill:'',
                    faucetDrillPosition:'',
                    faucetHandDrillPosition:'',
                    automaticValve:'NO',
                    automaticValvePosition:'',
                    dispenser:'NO',
                    dispenserPosition:'',
                    dispenserLujan:'',
                    totem:'NO',
                    cube:'NO',
                    cubeMachinedType:'',
                    slit:'NO',
                    slitHand:'',
                    
                    steelLegs:'NO',
                    legsHeight:'',
                    legFinish:'',
                    plateConnection:'NO',
                    totemConnection:'NO',
                    sealedSink:'NO',
                    plumbing:'NO',
                    plumbingPoints:''

                }

            })

            return
        }
        if(!this.orderData.clientOrder){
            Object.assign(this.orderData,{ clientOrder:{definitive:false,storeName: this.isAdminUser ? '' : store.getters.currentUser.user.client.companyName},})
        }
        if(!this.orderData.elaborationData){
            Object.assign(this.orderData,{
                accepted:"true",
                elaborationData:{ 
                    copete:'',
                    cantoEncimera:'',
                    cantoInglete:'',
                    zonaVolada:'',
                    costadosPatasSuelo:''
                },
                insertsAndAccesories:{
                    sinkMachinedType:'BAJO ENCIMERA',
                    sinkLujan:'',
                    handDrainer:'NO LLEVA',
                    faucetDrill:'',
                    faucetDrillPosition:'',
                    faucetHandDrillPosition:'',
                    automaticValve:'NO',
                    automaticValvePosition:'',
                    dispenser:'NO',
                    dispenserPosition:'',
                    dispenserLujan:'',
                    totem:'NO',
                    cube:'NO',
                    cubeMachinedType:'',
                    slit:'NO',
                    slitHand:'',
                    
                    steelLegs:'NO',
                    legsHeight:'',
                    legFinish:'',
                    plateConnection:'NO',
                    totemConnection:'NO',
                    sealedSink:'NO',
                    plumbing:'NO',
                    plumbingPoints:''




                }

            })
        }
        
        this.selectedClient=this.orderData.client
        
        if(this.orderData.clientOrder && this.orderData.clientOrder.measurementDate){
            this.orderData.clientOrder.measurementDate=this.orderData.clientOrder.measurementDate.split('T')[0]
        }

        if(this.selectedClient && this.selectedClient.companyName){
            this.orderData.clientOrder.storeName=this.selectedClient.companyName
        }
        
        
        if(this.orderData.elaborationData && this.orderData.elaborationData.material1){
            GetProductByID(this.orderData.elaborationData.material1).then((response) => {
                this.selectedProduct.product1=Object.values(response.data)[0]
                
            })

            
        }
        if(this.orderData.elaborationData && this.orderData.elaborationData.material2){
            GetProductByID(this.orderData.elaborationData.material2).then((response) => {
                this.selectedProduct.product2=Object.values(response.data)[0]
                
            })

            
        }
        if(this.orderData.elaborationData && this.orderData.elaborationData.material3){
            GetProductByID(this.orderData.elaborationData.material3).then((response) => {
                this.selectedProduct.product3=Object.values(response.data)[0]
                
            })

            
        }
        if(this.orderData.elaborationData && this.orderData.elaborationData.material4){
            GetProductByID(this.orderData.elaborationData.material4).then((response) => {
                this.selectedProduct.product4=Object.values(response.data)[0]

            })

            
        }

        if(this.orderData.attachedFiles && this.orderData.attachedFiles.length>0){
            this.GetAttachImages(this.orderData.attachedFiles)
            this.filesNames = this.filesNames.concat(this.orderData.attachedFiles)
        }
        
        if(this.duplicateOrder==true){
           
            delete this.orderData.id
            this.filesNames=[]
            this.filesArray=[]
            
        }
        if(this.orderData && this.orderData.id){
            GetOrderLineAdmin(this.orderData.id).then((response) => { 
                this.orderLinesData = response.data.items
                if(this.orderData.customOrderLines){
                    Object.assign(this.orderLinesData,JSON.parse(JSON.stringify(this.orderData.customOrderLines)))
                }
             })
			.catch(() => { store.commit('CreateToastMessage', { id: 'getOrderlineError', type: 'error', title: 'No se han podido obtener las lineas de pedido.', timeAlive: 5000 }) })
        }
        
    },

    computed:{

        GetTotalPVT() {
			if ( !this.orderLinesData ) { return 0 }
			let sum = 0
			Object.values(this.orderLinesData).forEach((i) => { sum += i.pvt })
			return sum
		},

		GetTotalPVP() {
			if ( !this.orderLinesData ) { return 0 }
			let sum = 0
			Object.values(this.orderLinesData).forEach((i) => { sum += i.pvp })
			if(this.orderData && this.orderData.totalDiscount){
				sum = sum - (sum * this.orderData.totalDiscount/100) 
			}
			if(this.orderData && this.orderData.totalVat){
				sum = sum + (sum * this.orderData.totalVat/100)
			}	

			return sum
		}

    },

    methods: {
        CurrencyNumberFormat,

        CheckFields:function(){
            
            //datos cliente
            if(!this.orderData.clientOrder.name || !this.orderData.clientOrder.address || !this.orderData.clientOrder.phones || !this.orderData.clientOrder.storeName || !this.orderData.clientOrder.measurementDate){
                this.OnMenuElementClick(this.$refs.menuOption0, 0)
                return false
            }

            //Datos de elaboración
            if(!this.selectedProduct.product1 || !this.orderData.elaborationData.vuelosEncimeraFrente || !this.orderData.elaborationData.vuelosEncimeraCostados){
                this.OnMenuElementClick(this.$refs.menuOption1, 1)
                return false
            }

            //Encastres y accesorios

            if(!this.orderData.insertsAndAccesories.plateBrand || !this.orderData.insertsAndAccesories.plateUbication || !this.orderData.insertsAndAccesories.plateModel){
                this.OnMenuElementClick(this.$refs.menuOption2, 2)
                return false

            }

            if(!this.orderData.insertsAndAccesories.sinkBrand || !this.orderData.insertsAndAccesories.sinkUbication || !this.orderData.insertsAndAccesories.sinkModel){
                this.OnMenuElementClick(this.$refs.menuOption2, 2)
                return false

            }

            //valvula automatica
            if(this.orderData.insertsAndAccesories.automaticValve=='SI'){
                if(!this.orderData.insertsAndAccesories.automaticValvePosition){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }
                
            //Dispensador
            if(this.orderData.insertsAndAccesories.dispenser=='SI'){
                if(!this.orderData.insertsAndAccesories.dispenserPosition || !this.orderData.insertsAndAccesories.dispenserModel){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }

            //Totem
            if(this.orderData.insertsAndAccesories.totem=='SI'){
                if(!this.orderData.insertsAndAccesories.totemNumber || !this.orderData.insertsAndAccesories.totemModel || !this.orderData.insertsAndAccesories.insertDiameter){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }

            //Cubo
            if(this.orderData.insertsAndAccesories.cube=='SI'){
                if(!this.orderData.insertsAndAccesories.cubeModel || !this.orderData.insertsAndAccesories.cubeMachinedType){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }
            
            
            //Cubo
            if(this.orderData.insertsAndAccesories.slit=='SI'){
                if(!this.orderData.insertsAndAccesories.slitHand || !this.orderData.insertsAndAccesories.slitNumber){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }

            //Patas de acero
            if(this.orderData.insertsAndAccesories.steelLegs=='SI'){
                if(!this.orderData.insertsAndAccesories.legsHeight || !this.orderData.insertsAndAccesories.legFinish || !this.orderData.insertsAndAccesories.legFinishHeight || !this.orderData.insertsAndAccesories.legFinishWidth){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }

            //Patas de acero
            if(this.orderData.insertsAndAccesories.plumbing=='SI'){
                if(!this.orderData.insertsAndAccesories.plumbingPoints){
                    this.OnMenuElementClick(this.$refs.menuOption2, 2)
                    return false
                }

            }
            
        },

        SendMessage: function(){
        
            SendMessageToClient({chatID:this.chatId,text:this.message}).then(()=>{
                this.message=''     
            }).catch((response) => {
                console.log(response)
            })
            
        },

        downloadImage: async function(src,name){
            

            const link = document.createElement('a');
            link.download= name;
            link.href = src;
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        FinishBudget: async function(){
            if(this.orderData && this.orderData.status !== '0' && this.orderData.status !== undefined && this.CreateUserClientEstimate!=undefined){
                store.commit('CreateToastMessage', { id: 'noResults', type: 'error', title: 'No se ha podido modificar', text: 'No se puede modificar este pedido porque está en un proceso avanzado, contacte con Luján para más información', timeAlive: 6000 })
                return
            }
            if(this.loader){ return }
            this.loader=true
            
            if(this.CheckFields() == false){
                store.commit('CreateToastMessage', { id: 'noResults', type: 'error', title: 'Faltan campos por rellenar', text: 'Por favor rellene todos los campos obligatorios', timeAlive: 6000 })
                this.loader=false
                return
            }
            if(this.orderData && this.orderData.id){
                
                this.orderData.elaborationData.material1 = this.selectedProduct.product1 ? this.selectedProduct.product1.id ?? undefined : undefined
                this.orderData.elaborationData.material2 = this.selectedProduct.product2 ? this.selectedProduct.product2.id ?? undefined : undefined
                this.orderData.elaborationData.material3 = this.selectedProduct.product3 ? this.selectedProduct.product3.id ?? undefined : undefined
                this.orderData.elaborationData.material4 = this.selectedProduct.product4 ? this.selectedProduct.product4.id ?? undefined : undefined
                
                await this.UpdateOrder()
                await this.DeleteSelectedFiles() 
                await this.UploadFiles()
                
                if ( this.callback ) { await this.callback() }	
				store.commit("ShowGeneralModalParameters", undefined)

                    }else{
                this.orderData.elaborationData.material1 = this.selectedProduct.product1 ? this.selectedProduct.product1.id ?? undefined : undefined
                this.orderData.elaborationData.material2 = this.selectedProduct.product2 ? this.selectedProduct.product2.id ?? undefined : undefined
                this.orderData.elaborationData.material3 = this.selectedProduct.product3 ? this.selectedProduct.product3.id ?? undefined : undefined
                this.orderData.elaborationData.material4 = this.selectedProduct.product4 ? this.selectedProduct.product4.id ?? undefined : undefined
                await this.CreateOrder()
                await this.UploadFiles()
                if ( this.callback ) { await this.callback() }	
                store.commit("ShowGeneralModalParameters", undefined)
            }

            
            
        },

        CreateOrder: async function(){
            this.orderData.client = this.selectedClient ? this.selectedClient.id  ? this.selectedClient.id : this.selectedClient._id: undefined
            
            await CreateOrderAdmin(this.orderData).then((response) => {
                
                this.orderData=Object.values(response.data)[0]
                
            }).catch((error) => {
                console.log(error)
            })

        },

        UpdateOrder: async function(){
           delete this.orderData["attachedFiles"]
           //this.orderData.client = this.selectedClient ? this.selectedClient.id  ? this.selectedClient.id : this.selectedClient._id: undefined
           
            UpdateOrderAdmin(this.orderData.id,this.orderData).catch((error) => {
                console.log(error.response)
            })

        },

        UploadFiles: async function(){
            
            let filesToSent=[]
            
            this.filesSelected.forEach((file) =>{
                
                if(this.filesNames.includes(file.name)){
                    filesToSent.push(file)
                }
               
            })
            if(filesToSent.length==0){ return }
            await UploadFileToOrder(filesToSent,this.orderData.id).then(() => {
                
            }).catch((error) => {
                console.log(error.response)
            })
        },

        DeleteAttachImage: function(name,index){
                
                this.filesArray.splice(index,1)
                this.filesToDelete.push(name)

                if(this.filesNames.includes(name)){
                    this.filesNames.splice(this.filesNames.indexOf(name),1)
                }
                

        },

        DeleteSelectedFiles: async function(){

            let filesToSent=[]
            
            this.filesToDelete.forEach((file) =>{
                
                if(!this.filesNames.includes(file.name)){
                    filesToSent.push(file)
                }
               
               
            })
            await DeleteFiles(filesToSent,this.orderData.id).then(() => {
               
            }).catch((error) => {
                console.log(error.response)
            })

        },

        GetAttachFile: async function(name){
            
            GetAttachFiles(name,this.orderData.id).then((response) => {
                
                
                // var reader = new FileReader();
                const blob = new Blob([response.data],{type: response.headers['content-type']});
                const link = document.createElement('a');
                link.download=name;
                link.href = window.URL.createObjectURL(blob);
               
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);


            }).catch((error) => {
                console.log(error.response)
            })
        },

        GetAttachImages: async function(names){
            names.forEach((name) => {

                GetAttachFiles(name,this.orderData.id).then((response) => {
               
                const blob = new Blob([response.data],{type: response.headers['content-type']});
             
                const url = window.URL.createObjectURL(blob);
                
                this.filesArray.push({name,url,type:blob.type})

            }).catch((error) => {
                console.log(error.response)
            })


            })

        },

        SelectFile: function(files){
            const allowedExtension=["application/pdf","image/png","image/jpeg","video/mp4"]
        
            Array.from( files ).forEach((file) => {
                if(allowedExtension.includes(file.type)){
                    this.filesSelected.push(file)
                    if(this.filesNames.includes(file.name)){
                        this.filesNames.splice(this.filesNames.indexOf(file.name),1)
                        
                    }
                    let url = URL.createObjectURL(file)
                    this.filesArray.push({name: file.name,
                    url,type:file.type})
                    this.filesNames.push(file.name)
                    
                }
            })

        },

       
        ClientSearch: function(){

			clearTimeout(this.searchTimeout)

			if ( !this.clientSearch ) { this.autocompleteClients = {};this.loadingClients = false; return }

			this.loadingClients = true

			this.searchTimeout = setTimeout(() => {

				GetClientsAdmin({companyName: this.clientSearch}).then((response) => {

					this.loadingClients = false
					if ( response.data.total === 0 ) { store.commit('CreateToastMessage', { id: 'noResults', type: 'error', title: 'No se ha encontrado ningún resultado', text: 'No existe ningún cliente en la base de datos', timeAlive: 6000 }) }
					this.autocompleteClients = response.data.items
				
				}).catch(() => { this.loadingClients = false })

			}, 500)

		},

        GetOrderLineTotals: function(orderLine){

			if ( orderLine.product === null || !orderLine.product.price ) { return 'NaN' }
			
			const discount = orderLine.discount ? orderLine.discount.toString().replace('%','') / 100 : 0
			//const vat = orderLine.vat ? orderLine.vat.toString().replace('%','') / 100 : 0
			if(orderLine.product.type=='ud'){

				const total = orderLine.product.price * orderLine.pieces
				const pvt = total 
				const pvp = total - ( total * discount )

				Object.assign(orderLine, { pvt, pvp } )

				return { pvt, pvp }

			}
			if(orderLine.product.type=='m2'){
				
				let meters = (orderLine.height * orderLine.width)/1000000
				
				if(isNaN(meters)){
					meters=0
				}
					
				const total = orderLine.product.price * meters
				const pvt = total 
				const pvp = total - ( total * discount )
				Object.assign(orderLine, { pvt, pvp, meters } )

				return { pvt, pvp }

			} 
			if(orderLine.product.type=='ml'){
				
				let meters = (orderLine.height * orderLine.width)/1000000
				
				if(isNaN(meters)){
					meters=0
				}
				
				const total = orderLine.product.price * (orderLine.height/1000)
				const pvt = total 
				const pvp = total - ( total * discount )
				Object.assign(orderLine, { pvt, pvp, meters } )

				return { pvt, pvp }

			} 
			
		},
 
        ProductSearchFunction: function(product){
            
            clearTimeout(this.searchTimeout)
            if ( !this.productSearch[product] ) { this.autocompleteProducts[product] = undefined;this.loadingProducts[product] = false; return }
            
            this.loadingProducts[product] = true
            
            
            this.searchTimeout = setTimeout(() => {
                SearchProduct(this.productSearch[product],this.orderData.feeType).then((response) => {
                    this.loadingProducts[product] = false
                    if ( response.data.total === 0 ) { store.commit('CreateToastMessage', { id: 'noResults', type: 'error', title: 'No se ha encontrado ningún resultado', text: 'No existe ningún producto en la base de datos', timeAlive: 6000 }) }
                    this.autocompleteProducts[product] = response.data.items
                }).catch((error) => {console.log(error)})
                
            }, 500)

        },
        OnMenuElementClick: function(menuElement, section){
                this.currentSection = section
                this.$refs.sectionMenuMark.style.left = menuElement.offsetLeft + 'px'
                this.$refs.sectionMenuMark.style.width = menuElement.offsetWidth + 'px'
            
			
        },
        PreventBlurEvent: function(event,product){
			
			if ( event.relatedTarget && event.relatedTarget.className === 'entriesSearch' ) { event.preventDefault() }
			else { this.autocompleteClients = undefined; this.autocompleteProducts[product] = undefined; }
		},

    }
}
</script>

<style>

.menuSection{
    padding: 12px;
    
}
.separator{
    background: var(--main_100);
    height: 0.5px;
    margin: 10px 0px;
    
}
.filesSelected{
    margin: 12px 0;
    padding: 12px 8px;
    border:1px solid var(--main_100);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filesSelected span{
    border: 1px solid var(--main_100);
    border-radius: 50px;
    padding: 4px 8px;
}

.filesArrayContainer{
    display: flex;
    flex-wrap: wrap;
    margin-top:10px;
    gap: 20px;
}

.filesArrayContainer .imageInArray {
    position: static;
    cursor: pointer;
}
.filesArrayContainer .imageInArray .cross{
    display: none;
    position: absolute;
    height: 15px;
    width: 15px;
    margin-top: 8px;
    margin-left: 180px;
    cursor: pointer;
    opacity: 1;
    
}

.filesArrayContainer .imageInArray .fileNameText{
    display: none;
    display: none;
    text-align: center;
    position: absolute;
    margin-top:100px;
    width:200px
}

.selectOverlay{
    display: none;
    background-color:var(--main_50);
    position:absolute;
    height:200px;
    width:200px;
    opacity:0.5;
    

}

.filesArrayContainer .imageInArray.selected .selectOverlay, .filesArrayContainer .imageInArray.selected .fileNameText{
    display: block;
}
.filesArrayContainer .imageInArray.selected .cross {
    display: block;
    cursor: pointer;
}
.addFilesButton{
    padding: 0px 24px;
    background: transparent;
    border: 1px solid var(--main_100);
    border-radius: 8px;
    cursor: pointer;
}

</style>