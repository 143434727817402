import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

const component = {

    setup() {
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
  
      const recaptcha = async () => {
        await recaptchaLoaded()
  
        // Execute reCAPTCHA with action "login".
        const token = await executeRecaptcha('login')
        console.log(token)
      }
  
      return {
        recaptcha
      }
    },

    template: ''
  }

createApp(App,component).use(store).use(router).use(VueReCaptcha, { siteKey: '6LebMOAgAAAAAFqqVin9xorxseo_LvkYIsY-OPI-' }).mount('#app')
