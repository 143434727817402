<template>
     
    <div class="topbar">

        <!-- <div class="brandLogo">Luján</div> -->

        <div class="brandLogo"><img src="@\assets\logo.png"></div>
        <div v-if="this.isAdmin" class="menuElement" ref="Analytics" v-bind:class="{'active': this.CurrentRouteName}"
            @click="this.$router.replace('analytics')">
            MÉTRICAS
        </div>

        <div class="menuElement" ref="Estimates" v-bind:class="{'active': this.CurrentRouteName}"
            @click="this.$router.replace('estimates')">
            PRESUPUESTOS
        </div>
        
        <div class="menuElement" ref="Orders" v-bind:class="{'active': this.CurrentRouteName}"
            @click="this.$router.replace('orders')">
            PEDIDOS
        </div>

        <div v-if="this.isAdmin" class="menuElement" ref="Clients" v-bind:class="{'active': this.CurrentRouteName}"
            @click="this.$router.replace('clients')">
            CLIENTES
        </div>

        <div v-if="this.isAdmin" class="menuElement" ref="Catalogue" v-bind:class="{'active': this.CurrentRouteName}"
            @click="this.$router.replace('catalogue')">
            PRODUCTOS
        </div>
        

        <!-- <div v-if="this.isAdmin" class="menuElement"ref="Catalogue_clients" v-bind:class="{'active': this.CurrentRouteName}"
            @click="this.$router.replace('catalogue_clients')">
            PRODUCTOS
        </div> -->

        <div class="menuMark" ref="menuMark"/>

        <div class="logOutContainer" v-if="this.currentUser && this.currentUser.user" >
            <div class="logOut" v-bind:class="{'open':this.showLogOutMenu}" @click="showLogOutMenu=!showLogOutMenu" tabindex="1" @blur="showLogOutMenu=false">{{this.currentUser.user.email}}<img src="..\assets\icons\chevronDownWhite.svg">
                
                <div class="logOutMenu" v-if="showLogOutMenu">
                    <div class="logOutMenuItem" style="color:#FFF" @click="this.LogOut()">
                        Cerrar Sesion
                    </div>
                </div>
            </div>
        </div>
        
    </div>
        
    
</template>

<script>
import store from '../store'
import { UserLogOut } from '../helpers/APIconnection'
export default {
    data: function() {
        return {
            showLogOutMenu:false
        }
    },

    computed:{
        CurrentRouteName(){
            this.OnMenuElementClick(this.$refs[this.$route.name])
            return this.$route.name
        },
        currentUser(){
            return store.getters.currentUser
        },
        isAdmin(){
            return store.getters.isAdmin
        }
    },

    mounted(){
        
        this.OnMenuElementClick(this.$refs[this.$route.name])
    },

    methods: {
        OnMenuElementClick: function(menuElement){
            if ( !menuElement ) return
            
            this.$refs.menuMark.style.left = menuElement.offsetLeft + 'px'
            this.$refs.menuMark.style.width = menuElement.offsetWidth + 'px'
        },

        LogOut:function(){
            UserLogOut().then(() => {
                this.$router.replace('login')
            }).catch((error) => {
                console.log(error.response)
            })
        }
    }
}
</script>

<style>

.topbar {
    position: relative;
    display: flex;
    width: 100%;
    gap: 12px;
    background: var(--main_100);
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.6);
    padding: 8px 0;
}

.topbar .brandLogo {
    
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    margin: 0 72px 0 24px;
    color: #FFF;
    padding: 2px;
    background: #FFF;
    border-radius: 8px;
    min-width: 204px;
    overflow: hidden;
    

}

.topbar .menuElement {
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    color: #FFF;

    transition: color 0.3s, background 0.3s;
}


.topbar .menuMark {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background: #FFF;
    transform: scaleX(0.85);

    transition: left 0.25s, width 0.3s;
}

.logOutContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;  
    
}

.logOut{
    width: 300px;
    margin-right: 32px;
    color: #FFF;
    cursor: pointer;
    display: flex;
    gap:10px;
    padding: 8px;
    
    
}

.logOut:hover{
    background: var(--main_75);
}

.logOut.open{
    background: var(--main_75);
}

.logOut .logOutMenu{
    
    opacity: 1;
    position: absolute;
    width: 300px;
    
    top: calc(100% - 14px); 
    right: 32px;  
    
    padding: 4px 0;
    background: var(--main_50);
    z-index: 50;
    animation: logOutApear 0.1s;
     
}

@keyframes logOutApear{
    0%{
        top: calc(100% - 20px);
    }
    100%{
        top: calc(100% - 14px);
    }

}

.logOutMenu .logOutMenuItem{

    padding: 10px;
    
    
}
.logOutMenuItem:hover{
    
    background-color: var(--main_75);
    
}


</style>