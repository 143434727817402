<template>
    <div class="ordersView">

        <div class="viewHeader">
            <div class="viewHeaderInfo">
                <p class="viewTitle">LISTADO DE <b>PEDIDOS</b></p>
            </div>
            <div style="display:flex;gap:10px">
                <button class="secondaryButton" v-if="this.entriesSelected.length>0" @click="this.deleteEstimate='VariedEntries'"> Eliminar</button>
                <button class="primaryButton" @click="OpenCreateBudgetModal"> + Nuevo</button>
            </div>
        </div>

        <Filters v-on:OnSearchButtonClick="this.GetOrders()" v-on:OnFiltersChange="Object.assign(this.filterValues, $event)" :viewConfig="this.filtersViewConfig"/>

        <div class="entriesTableContainer" v-if="this.entries && this.entries.count > 0">

            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.entries.count" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetOrders()"/>
            
            <table class="entriesTable" style="position: relative"> <!-- Registros obtenidos -->
                <tr>
                    <th style="width: 36px"> <div class="checkbox" :class="{'checked':this.entriesSelected.length>0}" @click="this.ToggleAllCheckBox();$event.stopPropagation()" /> </th>
                    <!--@click="$event.currentTarget.classList.toggle('checked')"-->
                    <th> Referencia </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['createAt']">Fecha <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th v-if="this.isAdminUser"> <div @click="this.SwitchFieldSorting(this.sortValues, 'clientName'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['clientName']">Tienda <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> Cliente </th>
                    <th> Dirección </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'city'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['city']">Localidad <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> Teléfono de Contacto </th>
                    <th>Presupuesto</th>
                    <th>Reserva de encimera</th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'totalAmount'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['totalAmount']">PVP <img src="@/assets/icons/arrow_white.svg" alt=""></div></th>
                    <th> Estado </th>
                    <th class="center" style="width: 36px">+</th>
                </tr>

                <tr v-for="order in this.entries.items" :key="order" :class="{'loading' : this.loadingEntries}"  tabindex="1" @blur="$event.currentTarget.classList.remove('showMenu')" >
                    <td @click="$event.stopPropagation()"> <div class="checkbox" :class="{'checked':this.entriesSelected.includes(order.id)}" @click="this.ToggleCheckBox(order.id)"/> </td>
                    <td>{{order.ref}}</td>
                    <td>{{this.FormatDate(order.createAt)}}</td>
                    <td v-if="this.isAdminUser">{{ order.client ? order.client.companyName ? order.client.companyName : order.client.dba : order.clientTempName ?? '[Tienda no encontrada]'}}</td>
                    <td>{{order.clientOrder?.name ?? '[ Cliente no asignado ]'}}</td>
                    <!-- <td v-if="this.isAdminUser">{{order.client ? order.client.type === 'individual' ? 'Particular' : 'Empresa' : '[ Cliente no encontrado ]'}}</td> -->
                    <td>{{order.clientOrder?.address ?? '[ Dirección no asignado ]'}}</td>
                    <td>{{order.clientOrder?.country ?? '[ Localidad no asignado ]'}}</td>
                    <td class="rightAlign">{{order.clientOrder?.phones ?? '[ Telefono no asignado ]'}}</td>
                    <!-- <td class="rightAlign">---</td> -->
                    <td @click="this.OpenEditEstimateModal(order)"><button>Acceder</button></td>
                    <td @click="$event.stopPropagation();this.OpenEditBudgetModal(order)"><button>Acceder</button></td>
                    <td class="rightAlign">{{order.totalAmount ? CurrencyNumberFormat(order.totalAmount) : 0}}</td>
                    <td >
                        <div class="circleContainer">
                            <div class="statusColorCircle"  :style="order.status==0 ? 'background:red' : 'background:green'"></div>
                            
                            <select v-model="order.status" @change="UpdateStatus(order)" v-if="this.isAdminUser">
								<option value="0">En proceso</option>
								<option value="1">Pedido de material</option>
								<option value="2">Medición</option>
								<option value="3">Terminado</option>
							</select>
                            <p v-else> {{ProductStateFormat(order.status)}}</p>
                            <!-- <img src="@/assets/icons/chevronDown.svg" alt="" > -->
                        </div>

                    
                    </td>
                    <td @click="$event.currentTarget.parentNode.classList.toggle('showMenu');$event.stopPropagation()"> <img src="@/assets/icons/chevronDown.svg" alt="" class="iconCentered"></td>

                    <div class="menuContainer">
                        <div class="triangle"></div>

                        <div class="menuItem" @click=";$event.stopPropagation();this.deleteEstimate=order">
                            <p>Borrar</p> <img src="..\assets\icons\trashIcon.svg"> 
                        </div>
                    </div>
                </tr>
            </table>
        </div>

        <div v-else-if="this.entries" class="noResults"> <!-- Registros no encontrados -->
            <img src="@/assets/icons/box.png" alt="">
            No se ha encontrado ningún pedido
        </div>

        <div v-else-if="this.loadingEntries" class="bigLoadingSpinner main center"/> <!-- Loading Spinner -->
    </div>

    <div class="deleteProductContainer" v-if="this.deleteEstimate">
            <div class="deleteProduct">
                    <p v-if="this.deleteEstimate==='VariedEntries'">Vas a eliminar todos los pedidos seleccionados <b>({{this.entriesSelected.length}})</b></p>
                    <p v-else>Vas a eliminar el pedido de <b v-if="this.deleteEstimate.client">{{this.deleteEstimate.client.companyName}}</b><b v-else>[ Cliente no encontrado ]</b> con referencia <b>{{this.deleteEstimate.ref}}</b> <br></p>
                    <p>¿Estás de acuerdo?</p>
                    <div class='buttonsContainer'>
                        <button @click="this.deleteEstimate=undefined">No</button>
                        <button v-if="this.deleteEstimate==='VariedEntries'" @click="this.DeleteEntriesSelected()">Sí</button>
                        <button v-else @click="this.DeleteOrder()">Sí</button>
                    </div>
                </div>
         </div>
</template>

<script>

import Filters from '../components/Filters.vue'
import TableHeader from '../components/TableHeader.vue'
import { GetOrders,DeleteOrdersAdmin,DeleteOrderAdmin,UpdateOrderAdmin } from '../helpers/APIconnection'
import { FormatDate, SwitchFieldSorting, CurrencyNumberFormat } from '../helpers/utils'
import store from '../store'

export default {

    data: function(){
        return {
            entries: undefined,
            loadingEntries: false,
            entriesSelected: [],
            deleteEstimate:undefined,
            isAdminUser: undefined,
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },

            sortValues: {createAt: 'desc'},
            filterValues: {},

            filtersViewConfig: {

                clientSearch: {
                    key: 'clientName',
                    order: 1
                },

                date: { order: 2 },

                simpleSearch: {
                    key: 'ref',
                    label: 'Referencia',
                    placeholder: 'P-00000',
                    order: 3
                },

                typeSelection: { order: 4 },

                orderStatus: { order: 5 }
            }
        }
    },

    components: {
        Filters,
        TableHeader
    },

    beforeMount(){

        this.GetOrders()
        this.isAdminUser=store.getters.isAdmin
    },

    methods: {

        SwitchFieldSorting,
        FormatDate,
        CurrencyNumberFormat,

        UpdateStatus: function(order){
            UpdateOrderAdmin(order.id,order).catch( (error)=> {
                console.log(error)
            })
        },
        DeleteEntriesSelected:function(){
            
            DeleteOrdersAdmin(this.entriesSelected).then(() => {
                
                this.deleteEstimate = undefined
                this.entriesSelected = []
                this.GetOrders()
            }).catch((error) => {
                console.log(error.response)
            })

        },

        DeleteOrder:function(){
            
            DeleteOrderAdmin(this.deleteEstimate.id).then(() => {
                
                this.deleteEstimate=undefined
                store.commit('CreateToastMessage', { id: 'deleteEstimates', type: 'error', title: 'Presupuesto eliminado', text: 'El presupuesto se ha eliminado correctamente', timeAlive: 5000 })
                this.GetOrders()
            }).catch((error) => {
                store.commit('CreateToastMessage', { id: 'deleteEstimates', type: 'error', title: 'Error al eliminar presupuesto', text: error.response.data.error[0].msg, timeAlive: 5000 })
                
            })

            

        },
        OpenCreateBudgetModal:function(){

            store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "NUEVO PRESUPUESTO", 
                        component:'CreateBudget',
                        userClient: this.isAdminUser ? undefined : store.getters.currentUser.user.client,
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%;'
                    }
                )

        },

        ToggleCheckBox:function(object){
            var indexOf= this.entriesSelected.indexOf(object)
            if(indexOf>-1){
                this.entriesSelected.splice(indexOf,1)
            }else{
                this.entriesSelected.push(object)
            }
            

        },

        ToggleAllCheckBox:function(){
           
            if(this.entriesSelected.length>0){
                this.entriesSelected=[]
            }else{
                
                this.entriesSelected=Object.keys(this.entries.items)
            }
            
        },

        GetOrders: function() {

            this.loadingEntries = true

            GetOrders(true, this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {

                this.loadingEntries = false
                this.entries = response

            }).catch((error) => {
                console.log(error.response)
                store.commit('CreateToastMessage', { id: 'getOrders', type: 'error', title: 'Error al obtener pedidos', text: error.response.data.error[0].msg, timeAlive: 5000 })
                this.loadingEntries = false
            })
        },

        ProductStateFormat: function( state ) {
            if ( state == 0 ) return 'En Proceso'
            else if ( state == 1 ) return 'Pedido de Materiales'
            else if ( state == 2 ) return 'Medición'
            else if ( state == 3 ) return 'Terminado'
             else return '-'
        },

        OpenCreateEstimateModal: function(){
            if(this.isAdminUser){
                    store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "NUEVO PRESUPUESTO", 
                        component:'CreateEstimate',
                        
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

            }else{
                store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "NUEVO PRESUPUESTO", 
                        component:'CreateEstimate',
                        userClient: store.getters.currentUser.user.client,
                        callback: () => {
                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

            }
            
        },

        OpenEditEstimateModal: function(order){
            if(this.isAdminUser){
                    store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "Editar Pedido: " + order.ref,
                            component:'CreateEstimate',
                            order,
                            callback: () =>{
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders()
                            } ,

                            style: 'height: 96%'
                        }
                    )
                }else{
                    store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "Editar Pedido: " + order.ref,
                            component:'CreateEstimate',
                            order,
                            userClient: store.getters.currentUser.user.client,
                            callback: () =>{
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders()
                            },

                            style: 'height: 90%'
                        }
                    )

                }
        },

        OpenEditBudgetModal:function(order){

            store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "Reserva de Encimera: " + order.ref, 
                        component:'CreateBudget',
                        order,
                        userClient: this.isAdminUser ? undefined : store.getters.currentUser.user.client,
                        
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

        }
    }

}
</script>

<style>

.ordersView {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 24px;
}

td button{
    width: 100%;
    border-radius: 8px;
    outline: none;
    border: transparent;
    padding: 8px;
    cursor: pointer;
    background: var(--main_50);
    color: white;
}

.circleContainer{
    position: static;
}

.circleContainer select{
    border: 0;
    background: transparent;
    outline: 0;
    padding: 4px;
    
    

}
</style>