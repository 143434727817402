<template>
     <div class='loginScreenContainer'>  
        
        <div class="leftLogo">
            
            <!--div class="leftDataContainer">
                <img src="../assets/logo.png">
            </div-->
        </div>

        <div class="signInUpContainer">

            <div class="loginForm"  > <!-------- LOGIN FORM -------->
                
                <p class="formTitle">Login</p>

                <div class="formInputContainer login">
                    <p class="label">Email:</p>
                    <input type="text" placeholder="Introduce tu email" spellcheck="false" v-model="this.email">
                    
                </div>

                <div class="formInputContainer login">
                    <p class="label">Contraseña:</p>
                    <input class="formInput" type="password" placeholder="Introduce tu contraseña" spellcheck="false" v-model="this.password">
                </div>

                
                <div class="checkboxContainer" @click="$event.currentTarget.querySelector('input').click()">

                    <input type="checkbox" @change="$event.currentTarget.checked ? this.rememberMe = true : localStorage.removeItem('utoken')">
                    <p>Recuérdame</p>
                </div>     
                

                <button class="loginButton" @click="this.Login()">
                    Iniciar sesión
                </button>

            </div>

        </div>
    </div>
</template>

<script>
import { UserLogIn } from '../helpers/APIconnection'
import store from '../store'

export default {

    data: function() {

        return {
            email: "",
            password: "",
            rememberMe: false,
        }

    },

    created() {

        const recaptchaBadge = document.querySelector('.grecaptcha-badge')
        if ( recaptchaBadge ) { recaptchaBadge.style.visibility = 'visible' }
    },

    unmounted() {

        const recaptchaBadge = document.querySelector('.grecaptcha-badge')
        if ( recaptchaBadge ) { recaptchaBadge.style.visibility = 'hidden' }
    },

     methods:{

        Login:function(){

            if ( !this.email || !this.password ) { return }
            
            UserLogIn(this.email,this.password).then((response) => {
                
                if ( this.rememberMe ) { localStorage.setItem('utoken', response.token) }
                if(response.user.admin==true){
                    this.$router.replace('analytics')
                }else{
                    this.$router.replace('estimates')
                }
                
                
            }).catch((error) => {

                if ( error.response && error.response.status === 401) store.commit('CreateToastMessage', { id: 'loginError', type: 'error', title: 'Acceso denegado', text: 'Email o contraseña incorrectos', timeAlive: 5000 })
                else if ( error.response && error.response.status === 418) store.commit('CreateToastMessage', { id: 'loginError', type: 'error', title: 'Acceso denegado', text: 'Seccion para clientes disponible próximamente', timeAlive: 5000 })
                else store.commit('CreateToastMessage', { id: 'serverError', type: 'error', title: 'El servidor no responde', text: 'Es momento de que te tomes un café...', timeAlive: 5000 })
            })
        }
    }
}

</script>
<style scoped>
    .grecaptcha-badge{
        visibility: visible;
    }
</style>

<style>

.loginScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.leftLogo {
    width: 700px;
    height: 100%;
    overflow: hidden;
    position:relative;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;      
}
.leftLogo::before {

    content: "";
    position: absolute;
    right: 0;
    width: 160%;
    height: 150%;
    background: var(--main_50);
    border-radius: 0 50% 50%  0;
      
}

.leftLogo::after{
    
    content: '';
    position: absolute;
    right: 0;
    width: 190%;
    height: 140%;
    background: var(--main_100);
    border-radius: 0 50% 50%  0;
      
}

.leftLogo .leftDataContainer {
    position: absolute;
    z-index: 1;
    color: #fff;
}


.leftLogo img {
    width: 200px;
    height: 50px;
    border-radius: 8px;
}

.signInUpContainer {
    width: 50%;
    height: 100%;
    display: flex; 
    align-content: center;
    justify-content: center;
    flex-direction: column;
    
}

.loginForm {
    padding: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column ;
    gap: 25px;
    font-family: 'Times New Roman';
    margin-left: 100px;
    border-radius: 8px;

    animation: nadiusko 0.8s; 
}

.loginForm .formTitle{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
}

@keyframes nadiusko{
    0%{
        transform: translateX(200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


.formInputContainer .label {
    font-size: 16px;
    font-weight: 500;
}

.formInputContainer input {
    height: 45px;
    width: 375px;
    padding: 8px;
    min-width: 250px;
    background: var(--main_10);
    border: 1px solid rgb(180, 178, 178);
    margin-top: 6px;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
}

.formInputContainer input:focus{
   box-shadow: 0px 0px 5px rgb(134, 133, 133);
}

.loginForm .loginButton {
    
    height: 56px;
    width: 100%;
    color: #fff;
    background: var(--main_100);
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    
}

.checkboxContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.checkboxContainer input {
    pointer-events: none;
    accent-color: var(--main_100);
}

</style>