import { createStore } from 'vuex'

export default createStore({
  
  state: {

    isAdmin: false,
    isAuthenticated: false,
    currentUser: {},
    
    appGlobalVariables:{
      generalModalParameters: undefined
    },

    toastMessagesQueue: {},
  },

  getters: {

    isAdmin: state => {
      return state.isAdmin
    },

    currentUser: state => {
      return state.currentUser
    },

    appGlobalVariables: state => {
      return state.appGlobalVariables
    },

    toastMessagesQueue: state => {
      return state.toastMessagesQueue
    },

  },

  mutations: {

    SetCurrentUser(state, data) {

      state.currentUser = data
      
      
      
      if ( data) {

        state.isAuthenticated = true
        sessionStorage.setItem('utoken', data.token)
        state.isAdmin=state.currentUser.user.admin
        

      } else {

        state.isAuthenticated = false
        sessionStorage.removeItem('utoken')
        localStorage.removeItem('utoken')
      }
    },

    ShowGeneralModalParameters(state, data) {
      //console.log('hola')
      state.appGlobalVariables.generalModalParameters = data
    },

    CreateToastMessage(state, data){

      if ( state.toastMessagesQueue[data.id] ) {
        clearTimeout(state.toastMessagesQueue[data.id].timeout)
                
        if ( data.override ) { Object.assign(state.toastMessagesQueue[data.id], data); return }
        else { state.toastMessagesQueue[data.id] = data }
        
      } else { state.toastMessagesQueue[data.id] = data }

      if ( data.timeAlive ) {
        state.toastMessagesQueue[data.id].timeout = setTimeout(() => { this.commit('RemoveToastMessage', data.id) }, data.timeAlive )
      }

    },

    RemoveToastMessage(state, id){
      delete state.toastMessagesQueue[id]
    },

  },

  actions: {
  },

  modules: {
  }
})
