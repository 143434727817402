<template>
    <div class="catalogueContainer">

        <div class="viewHeader">
            <div class="viewHeaderInfo">
                <p class="viewTitle">LISTADO DE <b>PRODUCTOS</b></p>
                <p style="font-weight: 700" v-if="this.productsList && this.productsList.total && this.currentLocation"><b>TARIFA: {{this.currentLocation}}</b></p>
                 <p style="font-weight: 700" v-if="this.productsList && this.productsList.total && this.filterValues.location"><b>PRODUCTOS TARIFA: {{this.productsList.count}}</b></p>
                <p style="font-weight: 700" v-if="this.productsList && this.productsList.total"><b>TOTAL DE PRODUCTOS: {{this.productsList.total}}</b></p>
            </div>
            <div style="display:flex;gap:10px">
                <button class="primaryButton" @click="this.OpenCreateProductModal()"> + Nuevo</button>
                <button class="secondaryButton" @click="$event.currentTarget.firstChild.click()" > <input type="file" style="display:none" 
                            accept=".xlsx"
                            @change="this.ImportProductFromExcel($event.currentTarget.files)">Importar Excel</button>
                <button class="secondaryButton" v-if="this.entriesSelected.length>0" @click="this.deleteProduct='VariedEntries'"> Eliminar</button>
            </div>
        </div>
        
        <Filters v-on:OnSearchButtonClick="this.paginationValues.entriesStart = 0;this.GetProductList()" v-on:OnFiltersChange="Object.assign(this.filterValues, $event)" :viewConfig="this.filtersViewConfig" />
        
        <div class="entriesTableContainer" v-if="this.productsList && this.productsList.count > 0">
            
            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.productsList.count" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetProductList()"/>

            <table class="entriesTable"> <!-- Registros obtenidos -->
                <tr>
                    <th style="width: 36px"> <div class="checkbox" @click="this.ToggleAllCheckBox()" :class="{'checked':this.entriesSelected.length>0}"/> </th>

                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'description'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['description']">Descripción <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['createAt']">Fecha Creación <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'updateAt'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['updateAt']">Ultima actualización <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'price'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['price']">Neto <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th class="center" style="width: 36px">+</th>
                </tr>

                <tr v-for="product in this.productsList.items" :key="product" :class="{'loading' : this.loadingEntries}"  tabindex="1" @blur="$event.currentTarget.classList.remove('showMenu')" @click="this.OpenViewProductModal(product)">
                    <td @click="$event.stopPropagation()"> <div class="checkbox" :class="{'checked':this.entriesSelected.includes(product.id)}" @click="this.ToggleCheckBox(product.id);$event.stopPropagation()"/> </td>
                    <td>{{product.description}}</td>
                    <td>{{FormatDate(product.createAt)}}</td>
                    <td>{{FormatDate(product.updateAt)}}</td>
                    <td class="rightAlign">{{CurrencyNumberFormat(product.price)}}</td>  

                    <td style="cursor:pointer" @click="$event.currentTarget.parentNode.classList.toggle('showMenu');$event.stopPropagation()"> <img src="@/assets/icons/chevronDown.svg" alt="" class="iconCentered" > </td>
                
                    <div class="menuContainer">
                        <div class="triangle"></div>
                        
                        <div class="menuItem" @click="this.UpdateProduct(product);$event.stopPropagation()">
                            <p>Editar</p> <img src="..\assets\icons\editIcon.svg">
                        </div>
                        <div class="menuItem" @click="this.deleteProduct=product;$event.stopPropagation()">
                            <p>Borrar</p> <img src="..\assets\icons\trashIcon.svg"> 
                        </div>
                    </div>

                </tr>
            </table>
        </div>

        <div v-else-if="this.productsList" class="noResults"> <!-- Registros no encontrados -->
            <img src="@/assets/icons/box.png" alt="">
            No se ha encontrado ningún producto
        </div>

        <div v-else-if="this.loadingEntries" class="bigLoadingSpinner main center"/> <!-- Loading Spinner -->

    </div>
    <div class="deleteProductContainer" v-if="this.importFile>0">
        <div class="deleteProduct">
            <div style="display:flex;flex-direction:column; align-items:center;gap:12px" v-if="this.importFile==1">
                <p> Se esta realizando una importación de productos, por favor no detenga la aplicacion</p>
                <p>Esta operacion puede durar unos minutos</p>
                <div class="bigLoadingSpinner main "></div>
            </div>
            <p v-if="this.importFile==2 && this.importFileResponse?.error">La importación no se ha podido completar</p>
            <p v-if="this.importFile==2 && this.importFileResponse?.error">{{this.importFileResponse.error.error}}</p>
            <p v-if="this.importFile==2 && this.importFileResponse?.finish">La importación ha terminado con exito</p>
            <p v-if="this.importFile==2 && this.importFileResponse?.finish">Productos importados: {{this.importFileResponse.finish.totalCount}}</p>
            <div class='buttonsContainer' v-if="this.importFile==2">
                <button @click="this.importFile=0;this.GetProductList()">Cerrar</button>
            </div>
        </div>
    </div>
    <div class="deleteProductContainer" v-if="this.deleteProduct">
            <div class="deleteProduct">
                    <p v-if="this.deleteProduct==='VariedEntries'"> Vas a eliminar todos los productos seleccionados <b>({{this.entriesSelected.length}})</b>.</p>
                    <p v-if="this.deleteProduct==='VariedEntries'"> Los productos que estén incluidos en un presupuesto no serán eliminados.</p>
                    <p v-else>Vas a eliminar el producto <b>{{this.deleteProduct.description}}</b> con referencia <b>{{this.deleteProduct.ref}}</b> <br></p>
                    <p>¿Estás de acuerdo?</p>
                    <div class='buttonsContainer'>
                        <button @click="this.deleteProduct=undefined">No</button>
                        <button v-if="this.deleteProduct==='VariedEntries'" @click="this.DeleteEntriesSelected()">Sí</button>
                        <button v-else @click="this.DeleteProduct()">Sí</button>
                    </div>
                </div>
         </div>
</template>

<script>
import Filters from '../components/Filters.vue'
import TableHeader from '../components/TableHeader.vue'
import {GetProducts, DeleteProduct, DeleteProductsAdmin,ImportProducts} from '../helpers/APIconnection'
import { FormatDate, SwitchFieldSorting,CurrencyNumberFormat } from '../helpers/utils'
import store from '../store'

export default {

    data: function(){
        return {
            productsList: undefined,
            deleteProduct:undefined,
            loadingEntries: false,
            currentLocation: "Madrid",
            entriesSelected:[],
            importFile:0,
            importFileResponse:{},

            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },

            sortValues: {createAt: 'desc'},
            filterValues: {location: "Madrid"},

            filtersViewConfig: {

                productSearch: { order: 1 },

                simpleSearch: {
                    key: 'ref',
                    label: 'Referencia',
                    placeholder: '000000',
                    order: 2
                },

                date: { order: 3 },

                location: {order: 4}
            }

        }
    },
    components: {
        Filters,
        TableHeader
    },
    mounted(){
        this.GetProductList()
    },
    methods:{
        FormatDate,
        SwitchFieldSorting,
        CurrencyNumberFormat,

        ImportProductFromExcel:function(files){

            const allowedExtension=["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

            if(allowedExtension.includes(files[0].type)){
                this.importFile = 1
                ImportProducts(files[0],this.currentLocation).then((response) => {
                    this.importFileResponse = response.data
                    console.log(this.importFileResponse)
                    this.importFile = 2
                }).catch((error) => {
                    console.log(error)
                })
            }  
        },

        DeleteEntriesSelected:function(){

            DeleteProductsAdmin(this.entriesSelected).then(() => {
                this.entriesSelected=[]
                this.deleteProduct=undefined
                this.GetProductList()
            }).catch((error) =>{ 
                console.log(error)

            })

        },

        ToggleCheckBox:function(object){
            var indexOf= this.entriesSelected.indexOf(object)
            if(indexOf>-1){
                this.entriesSelected.splice(indexOf,1)
            }else{
                this.entriesSelected.push(object)
            }
            

        },

        ToggleAllCheckBox:function(){
           
            if(this.entriesSelected.length>0){
                this.entriesSelected=[]
            }else{
                
                this.entriesSelected=Object.keys(this.productsList.items)
            }
            
        },

        OpenViewProductModal:function(product){
            
            store.commit(
                "ShowGeneralModalParameters",
                {
                    title: product.description,
                    component:'CreateProduct',
                    clientID: false,
                    product:product,
                    view:true,
                    callback: this.GetProductList
                }
            )
        },

        OpenCreateProductModal:function(){
            
            store.commit(
                "ShowGeneralModalParameters",
                {
                    title: "Nuevo producto",
                    component:'CreateProduct',
                    clientID: false,
                    
                    callback: () => {
                        this.sortValues= {createAt: 'desc'},
                        this.filterValues= {},
                        this.GetProductList()},
                }
            )
        },

        UpdateProduct:function(product){
             
            store.commit(
                "ShowGeneralModalParameters",
                {
                    title: product.description,
                    component:'CreateProduct',
                    clientID: false,
                    product:product,
                    callback: this.GetProductList
                }
            )
        },
        
        DeleteProduct:function(){
            
            DeleteProduct(this.deleteProduct.id).then(()=>{

                this.GetProductList()
                this.deleteProduct=undefined

            }).catch((error)=>{
                store.commit('CreateToastMessage', { id: 'DeleteClients', type: 'error', title: 'Error al eliminar producto', text: 'Error al eliminar producto', timeAlive: 5000 })
                console.log(error.response)
            })
        },

        GetProductList:function(){

            this.loadingEntries = true

            GetProducts(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {

                this.loadingEntries = false
                this.productsList = response.data
                this.currentLocation = this.filterValues.location
                
            
            }).catch((error) => {

                store.commit('CreateToastMessage', { id: 'getProducts', type: 'error', title: 'Error al obtener productos', text: error.response.data.error[0].msg, timeAlive: 5000 })
                this.loadingEntries = false
            })
        }

    }   

}
</script>

<style>

.catalogueContainer{
    padding: 24px;
}

.deleteProductContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteProduct{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--main_100);
    box-shadow: 5px 5px 20px var(--main_50);
}
.deleteProduct .buttonsContainer{
    margin-top: 16px;
    display: flex;
    gap:20px
}
.deleteProduct .buttonsContainer button{
    border:none;
    padding: 8px 16px;
    width: 90px;
    border-radius: 16px;
    outline: none;
    background: var(--main_75);
    color:#FFF;
    font-weight: bold;
    cursor: pointer;
}
</style>