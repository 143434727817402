import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Orders from '../views/Orders.vue'
import Catalogue from '../views/Catalogue.vue'
import Estimates from '../views/Estimates.vue'
import Analytics from '../views/Analytics.vue'
import Clients from '../views/Clients.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Catalogue_clients from '../views/Catalogue_clients.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/catalogue',
    name: 'Catalogue',
    component: Catalogue
  },

  {
    path: '/catalogue_clients',
    name: 'Catalogue_clients',
    component: Catalogue_clients
  },

  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  
  {
    path: '/estimates',
    name: 'Estimates',
    component: Estimates
  },
  
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },

  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics
  },

  {
    path: '/:catchAll(.*)',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
