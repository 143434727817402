<template>

    <div class="toastMessagesContainer">

        <div class="toastMessage" v-for="toast in this.GetToastMessages" v-bind:key="toast">

            <div class="messageBody">
                <div class="iconContainer" v-if="toast.type === 'success'" v-bind:style="{'background': this.GetTypeToastColor(toast.type)}"></div>
                <div class="iconContainer" v-else-if="toast.type === 'error'" v-bind:style="{'background': this.GetTypeToastColor(toast.type)}"></div>
                <div class="iconContainer" v-else-if="toast.type === 'warning'" v-bind:style="{'background': this.GetTypeToastColor(toast.type)}"></div>
                <div class="iconContainer" v-else-if="toast.type === 'info'" v-bind:style="{'background': this.GetTypeToastColor(toast.type)}"></div>
            
                <div class="textContainer">
                    <p class="title">{{toast.title}}</p>
                    <p class="text" v-if="toast.text">{{toast.text}}</p>
                </div>

                <!--div class="smallLoadingSpinner" v-if="toast.loader" v-bind:style="{'border-top-color': this.GetTypeToastColor(toast.type), 'border-right-color': this.GetTypeToastColor(toast.type)}"></div-->

                <img src="@/assets/icons/cross_white.svg" class="quitIcon" @click="this.RemoveToastMessage(toast.id)">

            </div>

            <div class="timerBar" :style="{'animation-duration': toast.timeAlive - 150 + 'ms'}"/>

        </div>

    </div>

</template>

<script>
import store from '../store'

export default {
    computed:{
        GetToastMessages(){
            return store.getters.toastMessagesQueue
        },
    },

    methods: {

        GetTypeToastColor: function(type){
            if ( type === 'success') { return '#5cb85c' }
            else if ( type === 'error') { return '#d9534f' }
            else if ( type === 'info') { return '#0275d8' }
            else if ( type === 'warning') { return '#f0ad4e' }
        },

        RemoveToastMessage: function(index){
            store.commit('RemoveToastMessage', index)
        },
    } 
}
</script>

<style>
.toastMessagesContainer {
    position: fixed;
    height: calc(100% - 64px);
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column-reverse;
    gap: 2px;
    padding: 8px;
    z-index: 500;
    pointer-events: none;
}

.toastMessagesContainer .toastMessage {
    position: relative;
    width: 400px;
    background: var(--main_100);
    border: 1px solid var(--main_50);
    border-radius: 4px;
    pointer-events: all;
    padding: 8px 12px;

    animation: toastEnter 0.3s;
}

.toastMessage .messageBody {
    display: flex;
}

.toastMessage .messageBody .textContainer {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 4px;
    padding-right: 16px;
}

.textContainer .title {
    font-weight: 700;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFF;
}

.textContainer .text {
    font-size: 14px;
    min-height: 24px;
    color: #FFF;
}

.toastMessage .messageBody .quitIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}

.toastMessage .timerBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #FFF;

  transform-origin: left;
  animation: timerBar linear forwards;
}

@keyframes timerBar {
  to {
    transform: scaleX(0);
  }
}

@keyframes toastEnter {
    from {
        transform: translateX(-400px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
</style>