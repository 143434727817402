<template>
    <div class="content">

        <div class="limitContainer">
            
            Muestra

            <div class="selector" @click="this.showMenu = !this.showMenu" tabindex="1" @blur="this.showMenu = false">

                <p>{{this.selectedLimitValue}}</p> <span class="separator"/><img src="@/assets/icons/chevronDown.svg" alt="">

                <div class="menuContainer" v-if="this.showMenu">
                    <div class="menuItem"
                        v-for="value in this.values.filter(e => this.selectedLimitValue != e)" :key="value"
                        @click="this.selectedLimitValue = value; this.showMenu = false; this.$emit('OnPaginationChange', {entriesLimit: value}); this.MovePagination(0, value); $event.stopPropagation()">
                        {{value}}
                    </div>
                </div>

            </div>
            
            elementos por página
        </div>

        <div class="paginationControl">

            {{this.paginationValues.entriesStart + 1 + '-' + this.ClampNumber(this.paginationValues.entriesStart + this.paginationValues.entriesLimit, 1, this.entriesTotal) }} de {{this.entriesTotal}}
            
            <button class="paginationButton" @click="this.MovePagination(-1)" :style="this.paginationValues.entriesStart <= 0 ? 'opacity: 0.3; pointer-events:none' : ''">
                <img src="@/assets/icons/chevronDown.svg" alt="" style="transform: rotateZ(90deg); margin-right: 1px">
            </button>

            <button class="paginationButton" @click="this.MovePagination(1)" :style="this.paginationValues.entriesStart + this.paginationValues.entriesLimit >= this.entriesTotal ? 'opacity: 0.3; pointer-events:none' : ''">
                <img src="@/assets/icons/chevronDown.svg" alt="" style="transform: rotateZ(-90deg); margin-left: 1px">
            </button>

        </div>
    </div>
</template>

<script>
export default {
    props: ['paginationValues', 'entriesTotal'],

    data: function(){
        return {
            values: [10, 30, 50, 75, 100],
            selectedLimitValue: undefined, // Valor de limite seleccionado.
            showMenu: false, // Condicional de renderización del desplegable.
        }
    },

    mounted(){

        this.paginationValues.entriesLimit ? this.selectedLimitValue = this.paginationValues.entriesLimit : this.selectedLimitValue = 100
    },

    methods: {

        MovePagination: function(direction){

            if ( this.selectedLimitValue >= this.entriesTotal ) {

                this.$emit('OnPaginationChange', {entriesStart: 0})

            } else {
                
                const newValue = this.ClampNumber(this.paginationValues.entriesStart + (this.paginationValues.entriesLimit * direction), 0, this.entriesTotal - 1)
                this.$emit('OnPaginationChange', {entriesStart: newValue})
            }
        },

        ClampNumber: function(num, min, max){
            return Math.max(min, Math.min(num, max))
        }
    },
}
</script>

<style>

.content {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    user-select: none;
}

.content .limitContainer {
    display: flex;
    align-items: center;
}

.content .selector {
    position: relative;
    display: flex;
    width: 62px;
    align-items: center;
    margin: 0 4px;
    padding: 4px 4px;
    border: 1px solid var(--main_50);
    outline: none;
    border-radius: 4px;
    background: #FFF;
    cursor: pointer;
}

.content .selector p {
    width: 30px;
}

.content .selector .separator {
    height: 25px;
    width: 1px;
    margin: 0 4px;
    background: var(--main_50);
}

.content .selector .menuContainer {
    position: absolute;
    width: 100%;
    top: calc(100% + 4px);
    left: 0;
    display: flex;
    flex-flow: column;
    gap: 4px;
    border: 1px solid var(--main_50);
    border-radius: 4px;
    padding: 4px 0;
    background: #FFF;
    z-index: 50;

    animation: swipeUp 0.3s;
}

.selector .menuContainer .menuItem {
    width: 100%;
    padding: 4px 4px;
    transition: background 0.2s;
}

.selector .menuContainer .menuItem:hover {
    background: var(--main_10);
}


.paginationControl {
    display: flex;
    align-items: center;
    gap: 6px;
}

.paginationControl .paginationButton {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main_75);
    border-radius: 50%;
    padding: 2px;
    outline: none;
    cursor: pointer;
    background: transparent;
}

</style>