<template>
    <div class="estimatesView">

        <div class="viewHeader">
            <div class="viewHeaderInfo">
                <p class="viewTitle">LISTADO DE <b>PRESUPUESTOS</b></p>
                <!-- <p style="font-weight: 700">TOTAL PRESUPUESTADO {{CurrencyNumberFormat(this.budgetTotalAmount)}}</p> -->
            </div>
            <div style="display:flex;gap:10px">
                <button class="primaryButton" @click="this.OpenCreateEstimateModal()">+ Nuevo</button>
                <button class="secondaryButton" v-if="this.entriesSelected.length>0" @click="this.deleteEstimate='VariedEntries'"> Eliminar</button>
            </div>
        </div>

        <Filters v-on:OnSearchButtonClick="this.GetOrders()" v-on:OnFiltersChange="Object.assign(this.filterValues, $event)" :viewConfig="this.filtersViewConfig"/>
        
        <div class="entriesTableContainer" v-if="this.entries && this.entries.count > 0">

            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.entries.count" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetOrders()"/>

            <table class="entriesTable" style="position: relative">
                <tr>
                    <th style="width: 36px"> <div class="checkbox" :class="{'checked':this.entriesSelected.length>0}" @click="this.ToggleAllCheckBox();$event.stopPropagation()"/> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'ref'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['ref']">Referencia <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['createAt']">Fecha <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th v-if="this.isAdminUser"> <div @click="this.SwitchFieldSorting(this.sortValues, 'clientName'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['clientName']">Tienda<img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    
                    <th> Cliente Final </th>
                    <!-- <th v-if="this.isAdminUser"> Tipo </th> -->
                    <th> Dirección </th>
                    
                    
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'city'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['city']">Localidad <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> Teléfono de Contacto </th>
                    <!-- <th> PVP </th> -->
                    
                    <!-- <th>IVA</th>
                    <th>Descuento</th> -->
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'totalAmount'); this.paginationValues.entriesStart = 0; this.GetOrders()" :class="this.sortValues['totalAmount']">PVP <img src="@/assets/icons/arrow_white.svg" alt=""></div></th>
                    <th> Estado </th>
                    <th class="center" style="width: 36px">+</th>
                </tr>

                <tr v-for="order in this.entries.items" :key="order" :class="{'loading' : this.loadingEntries}" @click="OpenEditEstimateModal(order)" tabindex="1" @blur="$event.currentTarget.classList.remove('showMenu')">
                    <td @click="$event.stopPropagation()"> <div class="checkbox" :class="{'checked':this.entriesSelected.includes(order.id)}" @click="this.ToggleCheckBox(order.id)"/> </td>
                    <td>{{order.ref}}</td>
                    <td>{{this.FormatDate(order.createAt)}}</td>
                    <td v-if="this.isAdminUser">{{order.client ? order.client.companyName ? order.client.companyName : order.client.dba : order.clientTempName ?? '[Tienda no encontrada]'}}</td>
                    
                    <td>{{order.clientOrder?.name ?? '[ Cliente no asignado ]'}}</td>
                    <!-- <td v-if="this.isAdminUser">{{order.client ? order.client.type === 'individual' ? 'Particular' : 'Empresa' : '[ Cliente no encontrado ]'}}</td> -->
                    <td>{{order.clientOrder?.address ?? '[ Cliente no asignado ]'}}</td>
                    
                    <td>{{order.clientOrder?.country ?? '[ Cliente no asignado ]'}}</td>

                    <td class="rightAlign">{{order.clientOrder?.phones ?? '[ Cliente no asignado ]'}}</td>
                    <!-- <td class="rightAlign">{{CurrencyNumberFormat(order.totalAmount)}}</td> {{this.CurrencyNumberFormat(order.totalAmount)}} -->
                    
                    <!-- <td  class="rightAlign">{{order.totalVat ?order.totalVat+'%'  : 0+'%'}}</td>
                    <td  class="rightAlign">{{order.totalDiscount ?order.totalDiscount+'%'  : 0+'%'}}</td> -->
                    <td class="rightAlign">{{order.totalAmount ? CurrencyNumberFormat(order.totalAmount) : 0}}</td>
                    
                    <td>Pendiente</td>
                    <td @click="$event.currentTarget.parentNode.classList.toggle('showMenu');$event.stopPropagation()"> <img src="@/assets/icons/chevronDown.svg" alt="" class="iconCentered"> </td>

                    <div class="menuContainer" @click="$event.stopPropagation()">
                        <div class="triangle"></div>
                        
                        <div class="menuItem" @click="OpenEditEstimateModal(order)">
                            <p>Editar</p> <img src="..\assets\icons\editIcon.svg">
                        </div>
                        <div class="menuItem" @click="OpenDuplicateEstimateModal(order)">
                            <p>Duplicar</p> <img src="..\assets\icons\duplicateIcon_white.svg">
                        </div>
                        <div class="menuItem" @click="this.deleteEstimate=order;">
                            <p>Borrar</p> <img src="..\assets\icons\trashIcon.svg"> 
                        </div>
                    </div>
                </tr> 


            </table>
        </div>

        <div v-else-if="this.entries" class="noResults"> <!-- Registros no encontrados -->
            <img src="@/assets/icons/box.png" alt="">
            No se ha encontrado ningún presupuesto
        </div>

        <div v-else-if="this.loadingEntries" class="bigLoadingSpinner main center"/> <!-- Loading Spinner -->
    </div>

    <div class="deleteProductContainer" v-if="this.deleteEstimate">
            <div class="deleteProduct">
                    <p v-if="this.deleteEstimate==='VariedEntries'">Vas a eliminar todos los presupuestos seleccionados <b>({{this.entriesSelected.length}})</b></p>
                    <p v-else>Vas a eliminar el presupuesto de <b v-if="this.deleteEstimate.client">{{this.deleteEstimate.client.companyName}}</b><b v-else>[ Cliente no encontrado ]</b> con referencia <b>{{this.deleteEstimate.ref}}</b> <br></p>
                    <p>¿Estás de acuerdo?</p>
                    <div class='buttonsContainer'>
                        <button @click="this.deleteEstimate=undefined">No</button>
                        <button v-if="this.deleteEstimate==='VariedEntries'" @click="this.DeleteEntriesSelected()">Sí</button>
                        <button v-else @click="this.DeleteOrder()">Sí</button>
                    </div>
                </div>
         </div>
</template>

<script>

import Filters from '../components/Filters.vue'
import TableHeader from '../components/TableHeader.vue'
import { GetOrders, DeleteOrderAdmin, DeleteOrdersAdmin } from '../helpers/APIconnection'
import { FormatDate, SwitchFieldSorting, CurrencyNumberFormat } from '../helpers/utils'
import store from '../store'

export default {

    data: function(){
        return {
            entries: undefined,
            loadingEntries: false,
            deleteEstimate: undefined,
            entriesSelected: [],
            isAdminUser: undefined,
            
                

            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },

            sortValues: {createAt: 'desc'},
            filterValues: {},

            filtersViewConfig: {

                clientSearch: {
                    key: 'clientName',
                    order: 1
                },

                date: { order: 2 },

                simpleSearch: {
                    key: 'ref',
                    label: 'Referencia',
                    placeholder: 'P-00000',
                    order: 3
                },

                typeSelection: { order: 4 }
            }
        }
    },

    components: {
        Filters,
        TableHeader
    },

    beforeMount(){

        this.GetOrders()
        this.isAdminUser=store.getters.isAdmin
    },

    methods: {

        SwitchFieldSorting,
        FormatDate,
        CurrencyNumberFormat,

        DeleteEntriesSelected:function(){
            
            DeleteOrdersAdmin(this.entriesSelected).then(() => {
                
                this.deleteEstimate = undefined
                this.entriesSelected = []
                this.GetOrders()
            }).catch((error) => {
                console.log(error.response)
            })

        },

        ToggleCheckBox:function(object){
            var indexOf= this.entriesSelected.indexOf(object)
            if(indexOf>-1){
                this.entriesSelected.splice(indexOf,1)
            }else{
                this.entriesSelected.push(object)
            }
            

        },

        ToggleAllCheckBox:function(){
           
            if(this.entriesSelected.length>0){
                this.entriesSelected=[]
            }else{
                
                this.entriesSelected=Object.keys(this.entries.items)
            }
            
        },

        GetOrders: function() {

            this.loadingEntries = true

            GetOrders(false, this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {

                this.loadingEntries = false
                this.entries = response
              
            }).catch((error) => {
                console.log(error.response)
                store.commit('CreateToastMessage', { id: 'getEstimates', type: 'error', title: 'Error al obtener presupuestos', text: error.response.data.error[0].msg, timeAlive: 5000 })
                this.loadingEntries = false
            })
        },

        DeleteOrder:function(){
            
            DeleteOrderAdmin(this.deleteEstimate.id).then(() => {
                
                this.deleteEstimate=undefined
                store.commit('CreateToastMessage', { id: 'deleteEstimates', type: 'error', title: 'Presupuesto eliminado', text: 'El presupuesto se ha eliminado correctamente', timeAlive: 5000 })
                this.GetOrders()
            }).catch((error) => {
                store.commit('CreateToastMessage', { id: 'deleteEstimates', type: 'error', title: 'Error al eliminar presupuesto', text: error.response.data.error[0].msg, timeAlive: 5000 })
                
            })

            

        },

        ProductStateFormat: function( state ) {
            if ( state == 0 ) return 'Borrador'
            else if ( state == 1 ) return 'Aprobado'
            else if ( state == 2 ) return 'Rechazado'
            else if ( state == 3 ) return 'En curso'
        },

        OpenDuplicateEstimateModal: function(duplicateOrder){
            if(this.isAdminUser){
                    store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "DUPLICAR PRESUPUESTO", 
                            component:'CreateEstimate',
                            duplicateOrder,
                            callback: () => {
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders();
                            },

                            style: 'height: 96%'
                        }
                    )

            }else {
                store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "DUPLICAR PRESUPUESTO", 
                            component:'CreateEstimate',
                            duplicateOrder,
                            userClient: store.getters.currentUser.user.client,
                            callback: () => {
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders();
                            },

                            style: 'height: 96%'
                        }
                    )
            }
        },

        OpenCreateBudgetModal:function(){

            store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "NUEVO PRESUPUESTO", 
                        component:'CreateBudget',
                        userClient: this.isAdminUser ? undefined : store.getters.currentUser.user.client,
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%;'
                    }
                )

        },

        OpenViewBudgetModal:function(order){

            store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "Visualizar Presupuesto: " + order.ref, 
                        component:'CreateBudget',
                        order,
                        view:true,
                        userClient: this.isAdminUser ? undefined : store.getters.currentUser.user.client,
                        
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

        },

        OpenEditBudgetModal:function(order){

            store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "Editar Presupuesto: " + order.ref, 
                        component:'CreateBudget',
                        order,
                        userClient: this.isAdminUser ? undefined : store.getters.currentUser.user.client,
                        
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

        },

        OpenDuplicateBudgetModal:function(order){

            store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "DUPLICAR PRESUPUESTO", 
                            component:'CreateBudget',
                            order,
                            duplicateOrder:true,
                            callback: () => {
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders();
                            },

                            style: 'height: 96%'
                        }
                    )

        },

        OpenCreateEstimateModal: function(){
            if(this.isAdminUser){
                    store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "NUEVO PRESUPUESTO", 
                        component:'CreateEstimate',
                        
                        callback: () => {

                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

            }else{
                store.commit(
                    "ShowGeneralModalParameters",
                    {
                        title: "NUEVO PRESUPUESTO", 
                        component:'CreateEstimate',
                        userClient: store.getters.currentUser.user.client,
                        callback: () => {
                            this.sortValues= {createAt: 'desc'},
                            this.filterValues= {},
                            this.GetOrders();
                        },

                        style: 'height: 96%'
                    }
                )

            }
            
        },

        OpenEditEstimateModal: function(order){
            if(this.isAdminUser){
                    store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "Editar Presupuesto: " + order.ref,
                            component:'CreateEstimate',
                            order,
                            callback: () =>{
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders()
                            } ,

                            style: 'height: 96%'
                        }
                    )
                }else{
                    store.commit(
                        "ShowGeneralModalParameters",
                        {
                            title: "Editar Presupuesto: " + order.ref,
                            component:'CreateEstimate',
                            order,
                            userClient: store.getters.currentUser.user.client,
                            callback: () =>{
                                this.sortValues= {createAt: 'desc'},
                                this.filterValues= {},
                                this.GetOrders()
                            },

                            style: 'height: 90%'
                        }
                    )

                }
        }
    }

}
</script>

<style>

.estimatesView {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 24px;
}
</style>