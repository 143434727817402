<template>

<div class="filtersContainer">

  <div class="filter" v-if="this.viewConfig.clientSearch && this.isAdmin"> <!-- CLIENT SEARCH -->
    <p>Búsqueda de cliente</p>
    <input type="text" placeholder="Nombre de empresa" style="width: 250px" v-model="this.filterValues[this.viewConfig.clientSearch.key]" @input="this.ClientSearch(this.viewConfig.clientSearch.key)" @blur="this.PreventBlurEvent($event)" v-on:keydown.enter="this.$emit('OnSearchButtonClick')">

    <div class="entriesSearch">
      <div class="entryElement" tabindex="1" v-for="client in this.autocompleteEntries" :key="client" @click="this.filterValues[this.viewConfig.clientSearch.key] = client.companyName ? client.companyName : client.dba; this.autocompleteEntries = undefined">
        {{[client.companyName ? client.companyName : client.dba, client.clientRef, client.postalAddress, client.postalCode, client.city].join(', ')}}
      </div>
    </div>
  </div>


  <div class="filter" v-if="this.viewConfig.productSearch"> <!-- PRODUCT SEARCH -->
    <p>Búsqueda de producto</p>
    <input type="text" placeholder="Descripción del producto" style="width: 250px" v-model="this.filterValues.description" @input="this.ProductSearch()" @blur="this.PreventBlurEvent($event)" v-on:keydown.enter="this.$emit('OnSearchButtonClick')">

    <div class="entriesSearch">
      <div class="entryElement" tabindex="1" v-for="product in this.autocompleteEntries" :key="product" @click="this.filterValues.description = product.description; this.autocompleteEntries = undefined">
        {{[product.ref, product.description, product.price].join(', ')}}
      </div>
    </div>
  </div>


  <!-- SIMPLE SEARCH -->

  <div class="filter" v-if="this.viewConfig.simpleSearch">
    <p>{{this.viewConfig.simpleSearch.label}}</p>
    <input type="text" :placeholder="this.viewConfig.simpleSearch.placeholder" v-model="this.filterValues[this.viewConfig.simpleSearch.key]" v-on:keydown.enter="this.$emit('OnSearchButtonClick')">
  </div>


  <!-- DATE -->

  <div class="filter" v-if="this.viewConfig.date">
    <div class="textFlex">
        <p>Fecha</p>
        <p class="spec">Desde</p>
    </div>
    <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.$emit('OnSearchButtonClick')">
  </div>

  <div class="filter" v-if="this.viewConfig.date">
    <div class="textFlex end">
        <p class="spec">Hasta</p>
    </div>
    <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.$emit('OnSearchButtonClick')">
  </div>
  


  <div class="filter" v-if="this.viewConfig.typeSelection && this.isAdmin">
    <p>Tipo</p>
    <select @change="this.filterValues.type = $event.currentTarget.value">
      <option value="">Todos</option>
      <option value="company">Empresa</option>
      <option value="individual">Particular</option>   
    </select>
  </div>

  <div class="filter" v-if="this.viewConfig.location && this.isAdmin">
    <p>Tarifa</p>
    <select @change="this.filterValues.location = $event.currentTarget.value">
      <option value="Madrid">Madrid</option>
      <option value="Murcia">Murcia</option>   
    </select>
  </div>


  <div class="filter" v-if="this.viewConfig.statusSelection">
    <p>Estado</p>
    <select @change="this.filterValues.status = $event.currentTarget.value" >
      <option value="">Todos</option>
      <option value="true">Activo</option>
      <option value="false">Inactivo</option>
    </select>
  </div>

  <div class="filter" v-if="this.viewConfig.orderStatus">
    <p>Estado</p>
    <select @change="this.filterValues.status = $event.currentTarget.value">
      <option value="">Todos</option>
      <option value="0">En proceso</option>
			<option value="1">Pedido de material</option>
			<option value="2">Medición</option>
			<option value="3">Terminado</option>
    </select>
  </div>


  <div class="searchButtonContainer">  
    <button class="searchButton" :style="this.newFiltersDetected ? '' : ''" @click="this.newFiltersDetected = false; this.$emit('OnSearchButtonClick')">
      <img src='../assets/icons/searchIcon_white.svg'>
      <p>Buscar</p>
    </button>
  </div>
</div>

</template>

<script>
import { GetClientsAdmin,  SearchProduct } from '../helpers/APIconnection'
import store from '../store'


export default {
  props:['viewConfig'],

  data: function() {
    return {

      filterValues: {},
      searchTimeout: undefined,
      newFiltersDetected: false,

      autocompleteEntries: {},

    }
  },

  watch: {

    filterValues: {

      deep: true,
      handler(){

        this.$emit('OnFiltersChange', this.filterValues)
        if ( Object.values(this.filterValues).every((value) => !value) ) { this.$emit('OnSearchButtonClick') }
        else { this.newFiltersDetected = true }
      }
    }
  },

  computed:{
      isAdmin(){
       return store.getters.isAdmin
      }
  },

  methods: {

    PreventBlurEvent: function(event){

      if ( event.relatedTarget === null || event.relatedTarget.className !== 'entryElement' ) { this.autocompleteEntries = undefined }
    },

    ClientSearch: function(key){

      clearTimeout(this.searchTimeout)

      if ( !this.filterValues[key] ) { this.autocompleteEntries = undefined; return }

      this.searchTimeout = setTimeout(() => {

        if ( !this.filterValues[key] ) { return }

        GetClientsAdmin({companyName: this.filterValues.companyName}).then((response) => {

          this.autocompleteEntries = response.data.items

        }).catch(() => {})

      }, 500)

    },

    ProductSearch: function(){

      clearTimeout(this.searchTimeout)

      if ( !this.filterValues.description ) { this.autocompleteEntries = undefined; return }

      this.searchTimeout = setTimeout(() => {

        if ( !this.filterValues.description ) { return }
          if(this.filterValues.location == null) {this.filterValues.location = "Madrid"}
          SearchProduct(this.filterValues.description,this.filterValues.location).then((response) => {

            this.autocompleteEntries = response.data.items

          }).catch(() => {})
          
      }, 500)

    }
  },
}
</script>

<style>

/* FILTER CONTAINER */

.filtersContainer {

  display: flex;
  flex-wrap: wrap;
  background:var(--main_100);

  margin-top: 24px;
  padding: 16px;
  gap: 12px;
  
}

.filter {
  position: relative;
  height: 60px;
  width: fit-content;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  z-index: 10;
}

.filter .textFlex {
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter .textFlex.end {
  justify-content: flex-end;
}

.filter p {
  height: 24px;
  color: #fff;
}

.filter p:first-child {
  padding-left: 2px;
}

.filter .textFlex .spec {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}

.filter input {
  padding-left: 10px;
  outline: none;
  color: var(--main_100);
  height: 36px;
}

.filter input::placeholder {
  font-size: 14px;
  color: var(--main_50);
}

.filter select {

  padding:0  10px;
  outline: none;
  color: var(--main_50);
  height: 36px;
}



.filter .entriesSearch {
  position: absolute;
  top: calc(100% + 4px);
  left: 1px;
  width: calc(100% - 2px);
  background: #FFF;
  box-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.5);
  z-index: 10px;
  overflow-y: scroll;
  max-height: 400px;
}



.filter .entriesSearch div {
  font-size: 12px;
  padding: 8px;
  min-width: 100%;
  user-select: none;
  cursor: pointer;
  animation: showUp 0.3s;
}

.filter .entriesSearch div:hover {
  background: var(--main_10);
}



.searchButton {
  max-height: 36px;
  display: flex;
  align-items: center;
  background: var(--main_75);
  border-radius: 16px;
  font-weight: 700;
  outline: none;
  border:none;
  cursor: pointer;
  padding: 12px 28px;
  gap: 8px
  
}

.searchButton p {

  color: #fff;   
  
}

.searchButtonContainer {

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] { -moz-appearance:textfield; }

</style>