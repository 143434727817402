<template>
  
  <div class="catalogueContainer">

        <div class="viewHeader">
            <div class="viewHeaderInfo" >
                <p class="viewTitle">LISTADO DE <b>PRODUCTOS</b></p>
                <p style="font-weight: 700" v-if="this.productsList && this.productsList.total"><b>TOTAL DE PRODUCTOS: {{this.productsList.total}}</b></p>
            </div>
            <button class="primaryButton" style="opacity:0;cursor:auto">N</button>
        </div>
        
        <Filters v-on:OnSearchButtonClick="this.GetProductList()" v-on:OnFiltersChange="Object.assign(this.filterValues, $event)" :viewConfig="this.filtersViewConfig" />
        
        <div class="entriesTableContainer" v-if="this.productsList && this.productsList.count > 0">
            
            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.productsList.count" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetProductList()"/>

            <table class="entriesTable"> <!-- Registros obtenidos -->
                <tr>
                    
                    <th> Referencia </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'description'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['description']">Descripción <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'price'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['price']">Precio <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['createAt']">Fecha Creación <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'updateAt'), this.paginationValues.entriesStart = 0; this.GetProductList()" :class="this.sortValues['updateAt']">Ultima actualización <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    
                </tr>

                <tr v-for="product in this.productsList.items" :key="product" :class="{'loading' : this.loadingEntries}">
                    
                    <td >{{product.ref}}</td>
                    <td>{{product.description}}</td>
                    <td class="rightAlign">{{product.price}}€</td>
                    <td>{{FormatDate(product.createAt)}}</td>
                    <td>{{FormatDate(product.updateAt)}}</td>  


                    
                
  

                </tr>
            </table>
        </div>

        <div v-else-if="this.productsList" class="noResults"> <!-- Registros no encontrados -->
            <img src="@/assets/icons/box.png" alt="">
            No se ha encontrado ningún producto
        </div>

        <div v-else-if="this.loadingEntries" class="bigLoadingSpinner main center"/> <!-- Loading Spinner -->

    </div>
</template>

<script>

import { GetProductsClient } from '../helpers/APIconnection'
import Filters from '../components/Filters.vue'
import TableHeader from '../components/TableHeader.vue'
import { FormatDate, SwitchFieldSorting } from '../helpers/utils'
import store from '../store'
export default {

    data:function(){
      return{
        productsList: undefined,

        paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },

        sortValues: {createAt: 'desc'},
            filterValues: {},

            filtersViewConfig: {

                productSearch: { order: 1 },

                simpleSearch: {
                    key: 'ref',
                    label: 'Referencia',
                    placeholder: '000000',
                    order: 2
                },

                date: { order: 3 }
            }

      }
    },
    components: {
        Filters,
        TableHeader
    },
    mounted(){
        this.GetProductList()
    },

    methods:{

      FormatDate,
      SwitchFieldSorting,

      GetProductList:function(){

            this.loadingEntries = true

            GetProductsClient(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {

                this.loadingEntries = false
                this.productsList = response.data
            
            }).catch((error) => {

                store.commit('CreateToastMessage', { id: 'getProducts', type: 'error', title: 'Error al obtener productos', text: error.response.data.error[0].msg, timeAlive: 5000 })
                this.loadingEntries = false
            })
        }

    }
}
</script>

<style>

</style>