<template>
    <div class="createClientModal">
        
        <div class="sectionMenu" style="padding: 24px" >
            <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 0)" ref="defaultOption">General</div>
            <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 1)">Metodos de pago</div>
             <div class="sectionOption" @click="this.OnMenuElementClick($event.currentTarget, 2)" v-if="this.editClientView">Gestión Usuarios</div>

            <div class="sectionMenuMark" ref="sectionMenuMark"/>

            <div class="sectionMenuSeparator"></div>
        </div>

        <div class="createUser" v-if="this.currentSection === 0">

            <div class="contentScrollWrap">

                <div class="contentScroll" style="padding: 24px;">
                    <p class="title">Datos Generales</p>

                    <div class="dataInput" v-if="this.client.type=='company'">
                        <div class="dataFieldName" ref="clientCompanyNameTitle">Razón Social *</div>
                        <input class="dataFieldInput" type="text" placeholder="Razon social" v-model="this.client.companyName" ref="clientCompanyNameInput" @focus="$event.currentTarget.style='1px solid var(--main_75)';this.$refs.clientCompanyNameTitle.style.color='var(--main_75)';this.ControlInfoText()"/>
                    </div>
                    <div class="dataInput" v-if="this.client.type=='company'">
                        <div class="dataFieldName">Nombre comercial</div>
                        <input class="dataFieldInput" type="text" placeholder="Nombre comercial" v-model="this.client.dba"/>
                    </div>
                    <div class="dataInput" v-if="this.client.type=='individual'">
                        <div class="dataFieldName" ref="clientCompanyNameTitle">Nombre de cliente *</div>
                        <input class="dataFieldInput" type="text" placeholder="Nombre de cliente" v-model="this.client.companyName" ref="clientCompanyNameInput" @focus="$event.currentTarget.style='1px solid var(--main_75)';this.$refs.clientCompanyNameTitle.style.color='var(--main_75)';this.ControlInfoText()"/>
                    </div>


                    <div class="columnContainer">

                        <div class="column"> 
                            <div class="dataInput" v-if="this.client.type=='company'">
                                <div class="dataFieldName" ref="clientCifTitle">CIF *</div>
                                <input class="dataFieldInput" type="text" placeholder="A00000000" v-model="this.client.cif" ref="clientCifInput" @focus="$event.currentTarget.style='1px solid var(--main_75)';this.$refs.clientCifTitle.style.color='var(--main_75)';this.ControlInfoText()"/>
                            </div>
                            <div class="dataInput" v-if="this.client.type=='individual'">
                                <div class="dataFieldName" ref="clientCifTitle" >NIF *</div>
                                <input class="dataFieldInput" type="text" placeholder="00000000A" v-model="this.client.cif" ref="clientCifInput" @focus="$event.currentTarget.style='1px solid var(--main_75)';this.$refs.clientCifTitle.style.color='var(--main_75)';this.ControlInfoText()"/>
                            </div>
                        </div>

                        <div class="column"> 
                            <div class="dataInput">
                                <div class="dataFieldName" ><p>Tipo</p></div>
                                <select id="type" v-model="this.client.type">
                                    <option value="company">Empresa</option>
                                    <option value="individual">Particular</option> 
                                        
                                </select>
                            </div>
                        </div>

                    </div>

                    <div class="dataInput" v-if="this.client.type=='company'">
                        <div class="dataFieldName">Pers. de ref.</div>
                        <input class="dataFieldInput" type="text" placeholder="Persona de referencia" v-model="this.client.contactName"/>
                    </div>

                    <div class="dataInput">
                                <div class="dataFieldName" ><p>Tarifa</p></div>
                                <select id="type" v-model="this.client.feeType">
                                    <option value="Murcia">Murcia</option>
                                    <option value="Madrid">Madrid</option>
                                    <option value="Otra">Otra</option> 
                                        
                                </select>
                            </div>


                    <p class="title">Datos Postales</p>

                    <div class="dataInput">
                        <div class="dataFieldName">Dirección</div>
                        <input class="dataFieldInput" type="text" placeholder="Dirección del cliente" v-model="this.client.postalAddress"/>
                    </div>

                    <div class="columnContainer">

                        <div class="column"> 

                            <div class="dataInput">
                                <div class="dataFieldName">Localidad</div>
                                <input class="dataFieldInput" type="text" placeholder="Localidad del cliente" v-model="this.client.city"/>
                            </div>
                        </div>

                        <div class="column"> 

                            <div class="dataInput">
                                <div class="dataFieldName">Código</div>
                                <input class="dataFieldInput" type="number" placeholder="Código Postal" v-model="this.client.postalCode"/>
                            </div>
                        </div>

                    </div>

                    <div class="columnContainer">

                        <div class="column"> 

                            <div class="dataInput">
                                <div class="dataFieldName">Provincia</div>
                                <input class="dataFieldInput" type="text" placeholder="Provincia " v-model="this.client.province"/>
                            </div>
                        </div>

                        <div class="column"> 

                            
                        </div>

                    </div>

                    <p class="title">Datos de Contacto</p>

                    <div class="dataInput">
                        <div class="dataFieldName" style="max-width:105px">Mail</div>
                        <input class="dataFieldInput" type="text" placeholder="Correo electronico " v-model="this.client.contactEmail"/>
                    </div>

                    <div class="columnContainer">
                        <div class="column">
                            
                            <div class="dataInput">
                                <div class="dataFieldName">Teléfono</div>
                                <input class="dataFieldInput" type="text" placeholder="Teléfono del cliente" v-model="this.client.contactPhone1"/>
                            </div>

                            <div class="dataInput" v-if="this.client.id">
                                <div class="dataFieldName">Estado</div>
                                <select v-model="this.client.status">
                                    <option value="true">Activo</option> 
                                    <option value="false">Inactivo</option>  
                                </select>
                            </div>
                        </div>

                        <div class="column">

                            <div class="dataInput">
                                <div class="dataFieldName">Teléfono 2</div>
                                <input class="dataFieldInput" type="number" placeholder="Teléfono secundario" v-model="this.client.contactPhone2"/>
                            </div>
                        </div>

                    </div>

                    <p ref="infoText" style="font-size:12px;margin-top:8px" >Campo obligatorio *</p>
                </div>
            </div>


            <div class="footerContainer">
                
                <button class="primaryButton" @click="this.UpdateClient(this.client)" v-if="this.client.id"><img src="..\assets\icons\saveIco.svg"><p>Guardar y salir</p></button>
                <button class="primaryButton" @click="this.SaveNewClient()" v-else><img src="..\assets\icons\saveIco.svg"><p>Guardar y salir</p></button>
            </div>

        </div>

        <div class="createUser" v-else-if="this.currentSection === 1">

            <div class="contentScrollWrap">

                <div class="contentScroll" style="padding: 24px;">

                    <div class="dataInput">
                        <div class="dataFieldName" ref="clientCompanyNameTitle">IBAN</div>
                        <input class="dataFieldInput" type="text" placeholder="IBAN" v-model="this.client.iban" />
                    </div>
                    <div class="dataInput">
                        <div class="dataFieldName">Tipo de pago</div>
                        <select v-model="this.client.payType">
                            
                            <option>RECIBO 30 DIAS</option>
                            <option>RECIBO 45 DIAS</option>
                            <option>RECIBO 60 DIAS</option>
                            <option>RECIBO iNMEDIATO</option>
                            <option>TRANSFERENCIA</option>
                            <option>OTRO</option>
                        </select>
                    </div>
                    <div class="dataInput" v-if="this.client.payType==='OTRO'">
                        <div class="dataFieldName" ref="clientCompanyNameTitle">Otro tipo:</div>
                        <textarea style="height:60px;outline:none;width:90%;resize:none;padding:8px" resize="none" v-model="this.client.payTypeOther"></textarea>
                    </div>


                </div>    


            </div>   


        </div>

        <div class="userManagement" v-else-if="this.currentSection === 2">

            <div class="contentScrollWrap">

                <div class="contentScroll">

                    
                    <div class="addUsers">
                        <div class="addUsersIput">
                            <p>Nombre</p>
                            <input class="createUserInput" v-model="this.user.name">
                        </div>
                        <div class="addUsersIput">
                            <p>Email</p>
                            <input class="createUserInput" v-model="this.user.email">
                        </div>
                        <div class="addUsersIput">
                            <p>Contraseña</p>
                            <input class="createUserInput" type="password" v-model="this.user.password">
                        </div>
                        <div class="addUsersIput">
                            <button @click="this.CreateNewUser()">+ Añadir</button>
                        </div>

                    </div>

                    <table class="entriesTable" v-if="this.userList && Object.keys(this.userList).length>0">
                        <tr style="font-size:14px">
                            <th style="font-size:14px;width:140px"> Nombre </th>
                            <th style="font-size:14px"> Email </th>
                            <th style="font-size:14px;width:160px"> Contraseña </th>
                            <th class="center" style="width:70px;font-size:14px">+</th>
                        </tr>
                        
                        
                        
                        <tr v-for="user in this.userList" :key="user" class="notHover">
                            
                            
                            <td><p v-if="this.editUser && user.email == this.editUser.email"><input class="createUserInput" v-model="this.editUser.newName"></p><p v-else>{{user.name}}</p></td>
                            <td><p v-if="this.editUser && user.email == this.editUser.email"><input class="createUserInput" v-model="this.editUser.newEmail"></p><p v-else>{{user.email}}</p></td>
                            <td><p v-if="this.editUser && user.email == this.editUser.email"><input class="createUserInput" v-model="this.editUser.password" type="password" placeholder="Nueva contraseña"></p><p v-else>************************</p></td>
                            
                            
                            <td style="padding:0">


                                <div class="editUsersIcons" v-if="this.editUser && user.email == this.editUser.email" >
                                    <div class="imgContainer" title="Guardar cambios"  @click="this.UpdateUser()">
                                        <img src="..\assets\icons\saveIcon_main.svg"  >
                                    </div>   
                                    <div class="imgContainer" title="Descartar cambios" @click="this.editUser=undefined" >
                                        <img src="..\assets\icons\cross_main.svg" title="Descartar cambios" @click="this.editUser=undefined" >
                                    </div>  
                                </div>   
                                <div class="editUsersIcons" v-else>
                                    <div class="imgContainer" title="Editar Usuario" @click="this.EditUser(user)" >
                                        <img src="..\assets\icons\editIcon_main.svg"  >
                                    </div>
                                    <div class="imgContainer" title="Eliminar Usuario" @click="this.deleteUser=user" >
                                        <img src="..\assets\icons\trashIcon_main.svg" >
                                    </div>
                                </div>
                                
                            </td>

                        </tr>
                    </table>

                    <div v-else class="noResults" style="font-size:14px">
                        <img src="@/assets/icons/box.png" alt="" style="height:70px">
                        No se ha encontrado ningún usuario
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="deleteClientContainer" v-if="this.deleteUser">
            <div class="deleteProduct">
                    
                    <p>Vas a eliminar el usuario <b>{{this.deleteUser.name}}</b> con email <b>{{this.deleteUser.email}}</b>. <br>
                    </p>
                    <p>¿Estás de acuerdo?</p>
                    <div class='buttonsContainer'>
                        <button @click="this.deleteUser=undefined">No</button>
                        <button @click="this.DeleteUser()">Sí</button>
                    </div>

                </div>

         </div>
</template>

<script>

import { CreateClientAdmin,UpdateClientAdmin,CreateUser,GetUsersByID ,DeleteUserAdmin,UpdateUserAdmin} from '../helpers/APIconnection'
import store from '../store'

export default {
    data: function(){
        return{

            client: {}, // Datos del cliente, en edición son insertados en el HTML
            editClientView: false,
            currentSection: 0,
            user: {},
            userList: undefined,
            deleteUser:undefined,
            editUser:undefined

        }

    },

    beforeMount(){

        if (store.getters.appGlobalVariables.generalModalParameters.client){

            this.editClientView = true

            this.client = Object.create(store.getters.appGlobalVariables.generalModalParameters.client)
            
            GetUsersByID(store.getters.appGlobalVariables.generalModalParameters.client.id).then((response) => {
                this.userList = response.data.items
                
            }).catch((error) => {
                console.log(error.response)
            })

        } else {
            // Inicialización de datos del nuevo cliente.
            this.client.status = 'true';
            this.client.type = "company";
            this.client.feeType = "Murcia"
            this.client.payType = "RECIBO 30 DIAS"
        }
        
    },

    mounted(){
        this.OnMenuElementClick(this.$refs.defaultOption, 0)
    },


    methods: {

        UpdateUser:function(){

            var body = {
                name:this.editUser.newName,
                email:this.editUser.newEmail,
                password:this.editUser.password
            }

            UpdateUserAdmin(this.editUser.uid,body).then(() => {
                
                store.commit('CreateToastMessage', { id: 'updateUser', type: 'error', title: 'Usuario Actualizado', text: 'El usuario se ha actualizado correctamente', timeAlive: 5000 })
                this.GetUsers()
                this.editUser=undefined

            }).catch((error) => {
                store.commit('CreateToastMessage', { id: 'updateUser', type: 'error', title: 'Usuario No Actualizado', text: error.response.data.error[0].msg, timeAlive: 5000 })
            })
        },

        EditUser:function(user){
            
            this.editUser=Object.create(user)
            
            var newData={
                
                newName:this.editUser.name,
                newEmail:this.editUser.email
            }
            Object.assign(this.editUser, newData)
            

            
        },

        DeleteUser:function(){
            
            DeleteUserAdmin(this.deleteUser.uid).then(() => {
                
                this.GetUsers()
                store.commit('CreateToastMessage', { id: 'deleteUser', type: 'error', title: 'Usuario Eliminado', text: 'El usuario se ha eliminado correctamente', timeAlive: 5000 })
                this.deleteUser=undefined
            }).catch((error) =>{
                console.log(error.response)
            })
        },

        DuplicateUser:function(userElement){
            this.user.name=userElement.name
            this.user.email=userElement.email

        },

        GetUsers:function(){
            GetUsersByID(store.getters.appGlobalVariables.generalModalParameters.client.id).then((response) => {
                
                this.userList =response.data.items
                
                
            }).catch((error) => {
                console.log(error.response)
            })

        },

        CreateNewUser:function(){

            this.user.client = store.getters.appGlobalVariables.generalModalParameters.client.id
            
            CreateUser(this.user).then(() => {
                store.commit('CreateToastMessage', { id: 'createClient', type: 'error', title: 'Usuario Creado', text: 'El usuario se ha creado correctamente', timeAlive: 5000 })
                this.user={}
                this.GetUsers()
            }).catch((error) => {
                console.log(error.response)
                store.commit('CreateToastMessage', { id: 'createClient', type: 'error', title: 'Error', text: error.response.data.error[0].msg, timeAlive: 5000 })
            })
        },

        ControlInfoText:function(){
            if(this.$refs.clientCompanyNameTitle.style.color=='var(--main_75)' && this.$refs.clientCifTitle.style.color=='var(--main_75)'){
                this.$refs.infoText.style.color="var(--main_75)"
            }
        },

        CheckEmptyFields:function(){

            var fieldEmpty = false

            if (!this.client.companyName){
                this.$refs.clientCompanyNameInput.style="border:1px red solid"
                this.$refs.clientCompanyNameTitle.style.color="red"
                fieldEmpty=true
                this.$refs.infoText.style.color = "red"
            }
            if (!this.client.cif){
                this.$refs.clientCifInput.style="border:1px red solid"
                this.$refs.clientCifTitle.style.color="red"
                fieldEmpty=true
                this.$refs.infoText.style.color = "red"
            }
            
            return fieldEmpty
        },
        
        SaveNewClient:function(){

            

            if (!this.CheckEmptyFields()){
                Object.assign(this.client,{type : document.getElementById("type").value})

                CreateClientAdmin(this.client).then(() =>{
                    
                    store.getters.appGlobalVariables.generalModalParameters.callback()
                    store.commit("ShowGeneralModalParameters", undefined)
                    store.commit('CreateToastMessage', { id: 'CreateClient', type: 'error', title: 'Cliente creado con éxito', text: '', timeAlive: 5000 })
                    
                 }).catch((error) => {

                    store.commit('CreateToastMessage', { id: 'createClient', type: 'error', title: 'Error', text: error.response.data.error[0].msg, timeAlive: 5000 })
                })

            }
        },

        UpdateClient:function(){

           
            if(!this.CheckEmptyFields()){
            
                UpdateClientAdmin(this.client.id, this.client)
                .then(() => {
                    
                    store.getters.appGlobalVariables.generalModalParameters.callback()
                    store.commit('CreateToastMessage', { id: 'CreateClient', type: 'error', title: 'Cliente actualizado con éxito', text: '', timeAlive: 5000 })
                    store.commit("ShowGeneralModalParameters", undefined)
                })
                .catch((error) => { 
                    store.commit('CreateToastMessage', { id: 'updateClient', type: 'error', title: 'Error', text: error.response.data.error[0].msg, timeAlive: 5000 })
                    console.log(error.response)
                })

            }

        },
        

        OnMenuElementClick: function(menuElement, section){

			this.currentSection = section

            if ( !menuElement ) { return }
            
            this.$refs.sectionMenuMark.style.left = menuElement.offsetLeft + 'px'
            this.$refs.sectionMenuMark.style.width = menuElement.offsetWidth + 'px'
        },

    }

}
</script>

<style>

.createClientModal {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 750px;
}

.createUser {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.createUser .title {
    font-weight: bold;
    font-size: 14px;
}

.createUser .title:not(:first-child) {
    margin-top:24px;
}

.dataInput {
    display: flex;
    gap: 8px;
    margin-top:10px;
}

.dataInput select {

    width: 100%;
    padding: 4px;
    border: 1px solid var(--main_75);
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}

.dataInput .dataFieldName{
    
    min-width: 149px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.dataInput .dataFieldInput {
    width: 100%;
    height: 28px;
    font-size: 14px;
    outline: none;
    border:  1px solid var(--main_75);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
}

.columnContainer {
    display: flex;
    gap: 24px;
}

.columnContainer .column {
    width: 50%;
}

.userManagement {
    height: 100%;
    padding: 24px;
}

.userManagementInputs{
    display: flex;
    gap: 10px;
}

.createUserInput {
    
    height: 24px;
    width: 100%;
    outline: none;
    padding: 0 4px;
    font-size: 14px;
}

.editUsersIcons{
    
    display: flex;
    justify-content: space-between;
}
.editUsersIcons .imgContainer{
    padding: 8px;
    cursor: pointer;
    
    
}
.editUsersIcons .imgContainer:hover{
    background: var(--main_50);
}
    
    
.editUsersIcons .imgContainer img{
    height: 26px;
    width: 16px;

    

    
}


.createUserOptions{
    cursor:pointer;
    width:50%;
    text-align: center;
    padding: 6px;
    border-radius: 8px;
}

.createUserOptions:hover{
    background: var(--main_50);
}

.deleteClientContainer{
    
    position: absolute;
    height: 70%;
    width: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addUsers{
    display: flex;
    justify-content: space-between;
    background: var(--main_10);
    padding: 8px;
    margin-bottom: 10px;
    border:1px solid var(--main_100);
    
}

.addUsers .addUsersIput{
    display: flex;
    flex-direction: column; 
    justify-content: flex-end;
}

.addUsers .addUsersIput p{
    font-size: 12px;
}

.addUsersIput button{
    background: var(--cta_100);
    padding: 4px 24px;
    outline: none;
    border: none;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;

}
</style>