<template>
  <div class="analyticsContainer" v-if="this.data">
    <div class="viewHeader">
            <div class="viewHeaderInfo">
                <p class="viewTitle"><b>MÉTRICAS</b></p>                
            </div>
    </div>

    <div class="analyticsContainerBody">
        <div class="analytic">
            <div class="title" @click="this.$router.replace('estimates')">PRESUPUESTOS</div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Total:
                </div>
                <div class="dataTag">
                    {{this.estimates.total}}
                </div>
            </div>
            

        </div>
        <div class="analytic" @click="this.$router.replace('orders')">
            <div class="title">PEDIDOS</div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Total:
                </div>
                <div class="dataTag">
                    {{this.orders.total}}
                </div>
            </div>

            <div class="analyticTag">
                <div class="tagTitle">
                    En Proceso:
                </div>
                <div class="dataTag">
                    {{this.orders.status1 ?? '0'}}
                </div>
            </div>
            <div class="analyticTag">
                <div class="tagTitle">
                    Pedido de material:
                </div>
                <div class="dataTag">
                    {{this.orders.status2 ?? '0'}}
                </div>
            </div>
            <div class="analyticTag">
                <div class="tagTitle">
                    Medición:
                </div>
                <div class="dataTag">
                    {{this.orders.status3 ?? '0'}}
                </div>
            </div>
            <div class="analyticTag">
                <div class="tagTitle">
                    Terminado:
                </div>
                <div class="dataTag">
                    {{this.orders.status4 ?? '0'}}
                </div>
            </div>
            
        </div>
        <div class="analytic" @click="this.$router.replace('clients')">
            <div class="title">CLIENTES</div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Total:
                </div>
                <div class="dataTag">
                    {{this.clients.total}}
                </div>
                
            </div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Clientes activos:
                </div>
                <div class="dataTag">
                    {{this.clients.actives}}
                </div>
                
            </div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Clientes inactivos:
                </div>
                <div class="dataTag">
                    {{this.clients.total - this.clients.actives}}
                </div>
                
            </div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Clientes Madrid:
                </div>
                <div class="dataTag">
                    {{this.clients.madrid}}
                </div>
                
            </div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Clientes Murcia:
                </div>
                <div class="dataTag">
                    {{this.clients.murcia}}
                </div>
                
            </div>
        </div>
        <div class="analytic" @click="this.$router.replace('catalogue')">
            <div class="title">PRODUCTOS</div>

            <div class="analyticTag">
                <div class="tagTitle">
                    Total:
                </div>
                <div class="dataTag">
                    {{this.products.total}}
                </div>
            </div>
        </div>


    </div>

</div>
<div v-else-if="this.data==false" class="bigLoadingSpinner main center"/> <!-- Loading Spinner -->
</template>

<script>


import { GetAnalytics } from '../helpers/APIconnection'
export default {

    data:function(){
        return{
            estimates:{},
            orders:{},
            clients:{},
            products:{},
            data:false,

        }
    },

    mounted(){
        // GetOrders(false).then((response) => {
        //     console.log(response)
        //     this.estimates.total=response.count
            
        // })

        // GetOrders(true).then((response) => {
        //     this.orders.total=response.count
        // })
        
        GetAnalytics().then((response)=>{

            const resp = response.data
            this.orders.total= resp.totalOrders
            this.estimates.total=resp.totalBudgets
            this.clients.total=resp.totalClients
            this.products.total=resp.totalProducts
            this.clients.actives=resp.clientActives
            this.clients.madrid=resp.madridClient
            this.clients.murcia=resp.murciaClient
            this.orders.status1=resp.ordermedic
            this.orders.status2=resp.ordermont
            this.orders.status3=resp.orderfabr
            this.orders.status4=resp.orderprep
            this.orders.status5=resp.ordercort
            this.orders.status6=resp.ordersend
            this.data=true
        })

    }

}
</script>

<style>
    .analyticsContainer{
        padding: 24px;
        height: 80%;
        display: flex;
        flex-direction: column;
        gap: 12PX;
        
    }
    .analyticsContainerBody{
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
        height: 100%;
    }

    .analyticsContainerBody .analytic{
        height: 200px;
        width: 20%;
        min-width: 300px;
        background: linear-gradient(to bottom right, var(--main_100), rgb(255, 255, 255));;
        border-radius: 8px;
        padding: 24px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;

    }
    .analyticsContainerBody .analytic .title{
        font-weight: 600;
        font-size: 24px;
        color: white;

    }

    .analyticTag{
        background: rgb(255, 255, 255);
        border-radius: 8px;
        padding: 12px;
        display: flex;
        justify-content: center;
        gap: 12px;
        
    }
    .analyticTag div{
        font-weight: 500;
        font-size: 20px;
    }
</style>