export function FormatDate(date) {

    const dateObj = new Date(date)
    return `${dateObj.getDate().toLocaleString('es-ES', { minimumIntegerDigits: 2, useGrouping: false })}/${(dateObj.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}/${dateObj.getFullYear()}`
}

export function SwitchFieldSorting(sortValues, key){

    if ( !sortValues[key] ) {

        // [ TEMPORAL ] - Mientras el backend no soporte ordenación multiple.
        // Forma de eliminar todos los datos del sortValues, no se puede hacer con: sortValues = {} ya que se estaría remplazando la referencia del objeto.
        Object.keys(sortValues).forEach((key) => { delete sortValues[key] })
        sortValues[key] = 'desc'

    } else if ( sortValues[key] === 'desc' ) {

        sortValues[key] = 'asc'
    
    } else {
        delete sortValues[key];
        sortValues['createAt'] = 'desc'
    }
}


const currencyFormater = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', });

export function CurrencyNumberFormat(value, int = false){ return int ? `${Math.round(value)}€` : currencyFormater.format(value) }