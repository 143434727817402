<template>
    <div class="clientsScreenContainer">

        <div class="viewHeader">
            <div class="viewHeaderInfo">
                <p class="viewTitle">LISTADO DE <b>CLIENTES</b></p>
                <p v-if="this.clientsList && this.clientsList.total" style="font-weight: 700"><b>{{this.clientsList.total}} CLIENTES REGISTRADOS</b></p>
            </div>
            <div style="display:flex; gap:10px">
                <button class="primaryButton" @click="this.OpenCreateClientModal()"> + Nuevo</button>
                 <button class="secondaryButton" v-if="this.entriesSelected.length>0" @click="this.deleteClient='VariedEntries'"> Eliminar</button>
            </div>
        </div>

        <Filters v-on:OnSearchButtonClick="this.GetClients()" v-on:OnFiltersChange="Object.assign(this.filterValues, $event)" :viewConfig="this.filtersViewConfig" />

        <div class="entriesTableContainer" v-if="this.clientsList && this.clientsList.count > 0">

            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.clientsList.count" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetClients()"/>

            <table class="entriesTable"> <!-- Registros obtenidos -->
                <tr>
                    <th style="width: 36px"> <div class="checkbox" :class="{'checked':this.entriesSelected.length>0}" @click="this.ToogleAllCheckBox()"/> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'clientRef'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['clientRef']">ID <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['createAt']">Fecha de Alta <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'companyName'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['companyName']">Cliente <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'type'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['type']">Tipo <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'cif'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['cif']">CIF/NIF <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'contactEmail'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['contactEmail']">Mail <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'contactPhone1'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['contactPhone1']">Teléfono <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'postalAddress'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['postalAddress']">Dirección <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> <div @click="this.SwitchFieldSorting(this.sortValues, 'city'), this.paginationValues.entriesStart = 0; this.GetClients()" :class="this.sortValues['city']">Localidad <img src="@/assets/icons/arrow_white.svg" alt=""></div> </th>
                    <th> Estado </th>
                    <th class="center" style="width: 36px">+</th>
                </tr>

                <tr v-for="client in this.clientsList.items" :key="client" :class="{'loading' : this.loadingEntries}"  @click="this.OpenEditClientModal(client)" tabindex="1" @blur="$event.currentTarget.classList.remove('showMenu')">
                    <td @click="$event.stopPropagation()"> <div class="checkbox" :class="{'checked':this.entriesSelected.includes(client.id)}" @click="this.ToggleCheckBox(client.id);$event.stopPropagation()"/> </td>
                    <td class="rightAlign">{{client.clientRef}}</td>
                    <td>{{this.FormatDate(client.createAt)}}</td>
                    <td>{{client.dba ?? client.companyName }}</td>
                    <td>{{client.type === 'individual' ? 'Particular' : 'Empresa'}}</td>
                    <td>{{client.cif}}</td>
                    <td>{{client.contactEmail}}</td>
                    <td class="rightAlign">{{client.contactPhone1}}</td>
                    <td>{{client.postalAddress}} {{client.postalCode ? ', ' + client.postalCode : ''}}</td>
                    <td>{{client.city}}</td>
                    <td ><div class="circleContainer"><div class="statusColorCircle"  :style="client.status ? 'background:green' : 'background:red'"></div><p>{{client.status ? 'Activo' : 'Inactivo'}}</p></div></td>


                    <td style="cursor:pointer" @click="$event.currentTarget.parentNode.classList.toggle('showMenu');$event.stopPropagation()"> <img src="@/assets/icons/chevronDown.svg" alt="" class="iconCentered"> </td>

                    <div class="menuContainer">
                        <div class="triangle"></div>
                        <div class="menuItem" @click="this.OpenEditClientModal(client);$event.stopPropagation()">
                            <p>Editar</p> <img src="..\assets\icons\editIcon.svg">
                        </div>
                        <div class="menuItem" @click="this.deleteClient=client;$event.stopPropagation()">
                            <p>Borrar</p> <img src="..\assets\icons\trashIcon.svg"> 
                        </div>
                    </div>

                </tr>

            </table>
        </div>

        <div v-else-if="this.clientsList" class="noResults">
            <img src="@/assets/icons/box.png" alt="">
            No se ha encontrado ningún cliente
        </div>

        <div v-else-if="this.loadingEntries" class="bigLoadingSpinner main center"/> <!-- Loading Spinner -->

    </div>

    <div class="deleteProductContainer" v-if="this.deleteClient">
            <div class="deleteProduct">
                    <p v-if="this.deleteClient==='VariedEntries'">Vas a eliminar todos los clientes seleccionados <b>{{(this.entriesSelected.length)}}</b></p>
                    <p v-else>Vas a eliminar el cliente <b>{{this.deleteClient.companyName}}</b> con id <b>{{this.deleteClient.clientRef}}</b>. <br></p>
                    <p>Se eliminaran todos los elementos vinculados</p>
                    <p>¿Estás de acuerdo?</p>
                    <div class='buttonsContainer'>
                        <button @click="this.deleteClient=undefined" >No</button>
                        <button @click="this.DeleteEntriesSelected()" v-if="this.deleteClient==='VariedEntries'">Sí</button>
                        <button @click="this.DeleteClient()" v-else>Sí</button>
                    </div>

                </div>

         </div>

</template>

<script>

import Filters from '../components/Filters.vue'
import TableHeader from '../components/TableHeader.vue'
import { GetClientsAdmin, DeleteClient, DeleteClientsAdmin } from '../helpers/APIconnection'
import { FormatDate, SwitchFieldSorting } from '../helpers/utils'
import store from '../store'

export default {

    data: function() {

        return {
            clientsList: undefined,
            deleteClient: undefined,
            loadingEntries: false,
            entriesSelected:[],
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },

            sortValues: {createAt: 'desc'},
            filterValues: {},

            filtersViewConfig: {

                clientSearch: {
                    key: 'companyName',
                    order: 1
                },

                date: { order: 2 },

                simpleSearch: {
                    key: 'clientRef',
                    label: 'Referencia',
                    placeholder: '000',
                    order: 3
                },

                typeSelection: { order: 4 },

                statusSelection: { order: 5 }
            }
        }

    },

    components: {
        Filters,
        TableHeader
    },

    beforeMount(){

        this.GetClients()
        
    },

    methods: {

        SwitchFieldSorting,
        
        FormatDate,

        DeleteEntriesSelected:function(){

            DeleteClientsAdmin(this.entriesSelected).then(() => {
                
                this.entriesSelected=[]
                this.deleteClient=undefined
                this.GetClients()
            }).catch((error) => {
                console.log(error.response)
            })
        },

        ToggleCheckBox:function(object){
            var indexOf= this.entriesSelected.indexOf(object)
            if(indexOf>-1){
                this.entriesSelected.splice(indexOf,1)
            }else{
                this.entriesSelected.push(object)
            }
            

        },

        ToogleAllCheckBox:function(){
           
            if(this.entriesSelected.length>0){
                this.entriesSelected=[]
            }else{
                
                this.entriesSelected=Object.keys(this.clientsList.items)
            }
            
        },

        GetClients: function(){
            
            this.loadingEntries = true

            GetClientsAdmin(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {

                this.loadingEntries = false
                this.clientsList = response.data
            
            }).catch((error) => {

                store.commit('CreateToastMessage', { id: 'getClients', type: 'error', title: 'Error al obtener clientes', text: error.response.data.error[0].msg, timeAlive: 5000 })

                this.loadingEntries = false
            })
        },

        OpenCreateClientModal:function(){
            
            store.commit(
                "ShowGeneralModalParameters",
                {
                    title: "Nuevo cliente",
                    component:'CreateClient',
                    callback: () => {
                        this.sortValues= {createAt: 'desc'}
                        this.filterValues= {}
                        this.GetClients()
                    },
                    style: 'height: 80%'
                }
            )
        },

        OpenEditClientModal:function(client){
            
            
            store.commit(
                "ShowGeneralModalParameters",
                {
                    title: "Editar "+client.companyName,
                    component:'CreateClient',
                    client,
                    callback:this.GetClients,
                    style: 'height: 80%'
                    
                }
            )
        },

        DeleteClient:function(){
            
            DeleteClient(this.deleteClient.id).then(() => {
                this.GetClients()
                this.deleteClient=undefined
            }).catch((error) =>{
                store.commit('CreateToastMessage', { id: 'DeleteClients', type: 'error', title: 'Error al eliminar cliente', text: 'Error al eliminar cliente', timeAlive: 5000 })
                console.log(error.response)
            })
        }

    },
    

    
}

</script>

<style>

.clientsScreenContainer{
    padding: 24px;

}
.statusColorCircle{
    background: green;
    height: 8px;
    width: 8px;
    border-radius: 8px;
}

.circleContainer{
    display: flex;
    gap:8px;
    align-items: center;
    
}



</style>